import React from 'react';
import { DuotoneIcons } from '../../components/Atoms/icon/duotone-icon';
import { IStoryblokLink } from '../types';
import Header, { IHeaderNavigation } from '../../containers/header/header';
import { scrollToElement } from '../../helpers/scroll-to-element';
import { mapDesktopHeaderToMobile } from '../../containers/header/helpers/map-desktop-header-to-mobile';
import { IHeaderNavigationMenu } from '../../containers/header/header-menu/header-menu';
import { getLinkUrl } from '../helpers/get-link-url';

interface IHeaderStoryblokComponentBlok {
  items: {
    title: string;
    menues?: {
      title: string;
      items: {
        title: string;
        description: string;
        icon: DuotoneIcons;
        link: IStoryblokLink;
      }[];
    }[];
    onClick?: () => void;
  }[];
}
export function HeadertoryblokComponent({ blok }: { blok: IHeaderStoryblokComponentBlok }) {
  const headerNavigation: IHeaderNavigation[] = blok.items.map((item) => ({
    ...item,
    menues: item.menues?.map((menu) => ({
      ...menu,
      items: menu.items.map((item) => ({
        ...item,
        link: getLinkUrl(item.link),
      })),
    })),
  }));

  const headerNavigationMobile: IHeaderNavigationMenu[] = mapDesktopHeaderToMobile(headerNavigation);

  // TODO: Remove push "Contact Us" item after "Contact Us" page implementation

  const headerNavigationWithContactUsButton = [
    ...headerNavigation,
    {
      title: 'Contact Us',
      onClick: () => scrollToElement('#connect-section'),
    },
  ];

  const headerNavigationMobileWithContactUsButton = [
    {
      items: [
        {
          title: 'Contact Us',
          icon: DuotoneIcons.MOBILE,
          onClick: () => setTimeout(() => scrollToElement('#connect-section'), 500),
        },
      ],
    },
    ...headerNavigationMobile,
  ];

  return (
    <Header
      headerNavigation={headerNavigationWithContactUsButton}
      headerNavigationMobile={headerNavigationMobileWithContactUsButton}
    />
  );
}
