import React, { useState } from 'react';
import classNames from 'classnames';
import Text, { TextTypographies, TextWeights } from '../../components/Atoms/text/text';
import List, { ListDirections } from '../../components/Atoms/list/list';
import { ListItemTypes } from '../../components/Atoms/list/list-item';
import Icon from '../../components/Atoms/icon/icon';
import { IconscoutIcons } from '../../components/Atoms/icon/iconscout-icon';

export interface IFooterLink {
  href: string;
  text: string;
}
export interface IFooterColumn {
  title: string;
  links: IFooterLink[];
}
export interface IFooterSocialLink {
  href: string;
  icon: IconscoutIcons;
}
interface IFooterProps {
  columns: IFooterColumn[];
  copyrightNotice: string;
  socialLinks?: IFooterSocialLink[];
  additionalLinks?: IFooterLink[];
}
export const Footer = ({ columns, copyrightNotice, socialLinks, additionalLinks }: IFooterProps) => {
  const [activeColumnIdx, setActiveColumnIdx] = useState(0);

  const toggleColumn = (columnIdx: number) => {
    setActiveColumnIdx(columnIdx);
  };

  return (
    <footer className="footer">
      <ul className="footer__columns">
        {columns.map((column: IFooterColumn, idx) => (
          <li key={idx} className="footer__column">
            <Text
              className="footer__title"
              text={column.title}
              weight={TextWeights.BOLD}
              onClick={() => toggleColumn(idx)}
            />
            <List
              className={classNames(
                idx === activeColumnIdx ? 'footer__links-open' : 'footer__links-closed',
                'footer__links'
              )}
              direction={ListDirections.COLUMN}
              listItemType={ListItemTypes.TEXT}
              listItems={column.links.map((link) => ({
                link: link.href,
                description: {
                  text: link.text,
                  typography: TextTypographies.BODY_SMALL,
                  weight: TextWeights.REGULAR,
                },
              }))}
            />
          </li>
        ))}
      </ul>

      <div className="footer-bottom-section">
        <div className="footer-bottom-section__item">{copyrightNotice}</div>

        {socialLinks && socialLinks.length > 0 && (
          <div className="footer-bottom-section__socials">
            {socialLinks.map((social, idx) => (
              <a key={idx} href={social.href} className="footer-bottom-section__link">
                <Icon icon={social.icon} className="footer-bottom-section__social" />
              </a>
            ))}
          </div>
        )}

        {additionalLinks && additionalLinks?.length > 0 && (
          <div className="footer-bottom-section__links">
            {additionalLinks.map((link, idx) => (
              <a key={idx} href={link.href} className="footer-bottom-section__link">
                {link.text}
              </a>
            ))}
          </div>
        )}
      </div>
    </footer>
  );
};
