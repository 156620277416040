import React from 'react';
import TestimonialsSection from '../../containers/sections/testimonials-section/testimonials-section';
import { ITestimonialFullProps } from '../../components/testimonial/testimonial-full/testimonial-full';
import { IStoryblokLink } from '../types';

interface ITestimonialsSectionStoryblokComponentBlok {
  sectionTitle: string;
  testimonials: {
    logo: {
      filename: string;
    };
    text: string;
    title: string;
    avatar: {
      filename: string;
    };
    budget: string;
    duration: string;
    location: string;
    component: string;
    authorName: string;
    authorPosition: string;
    link?: IStoryblokLink;
  }[];
}
export function TestimonialsSectionStoryblokComponent({ blok }: { blok: ITestimonialsSectionStoryblokComponentBlok }) {
  const testimonials: ITestimonialFullProps[] = blok.testimonials.map((data) => ({
    author: { name: data.authorName, position: data.authorPosition },
    avatarUrl: data.avatar.filename,
    budget: data.budget,
    content: { logo: data.logo.filename, text: data.text, title: data.title },
    duration: data.duration,
    location: data.location,
    link: data.link?.cached_url || data.link?.url,
  }));

  return <TestimonialsSection sectionTitle={blok.sectionTitle} testimonials={testimonials} />;
}
