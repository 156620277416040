import React, { useState } from 'react';
import classNames from 'classnames';
import { IconscoutIcons } from '../Atoms/icon/iconscout-icon';
import Icon from '../Atoms/icon/icon';
import Text, { TextTypographies, TextWeights } from '../Atoms/text/text';
import { isInternalLink } from '../../helpers/is-link-external';
import { Link } from 'gatsby';

export interface IButtonSocialProps {
  textContent: string;
  iconName: IconscoutIcons;
  isActive?: boolean;
  isDisabled?: boolean;
  link?: string;
  onClick?: (...args: any[]) => void;
}

export const ButtonSocial = (props: IButtonSocialProps): JSX.Element => {
  const initialActiveState: boolean = props.isActive ?? false;
  const [isActive, setIsActive] = useState(initialActiveState);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }

    setIsActive((prev) => !prev);
  };

  const ButtonContent = () => (
    <>
      <Icon icon={props.iconName} />
      <Text
        text={props.textContent}
        typography={TextTypographies.BODY_SMALL}
        weight={TextWeights.SEMIBOLD}
        className="button-social__title"
      />
    </>
  );

  const buttonProps = {
    className: classNames('button-social', isActive && 'button-social-active'),
    onClick: handleClick,
  };

  if (!props.link) {
    return (
      <button {...buttonProps} disabled={!!props.isDisabled}>
        <ButtonContent />
      </button>
    );
  }

  if (isInternalLink(props.link)) {
    return (
      <Link {...buttonProps} to={`/${props.link}`}>
        <ButtonContent />
      </Link>
    );
  } else {
    return (
      <a {...buttonProps} href={props.link} target="_blank" rel="noreferrer">
        <ButtonContent />
      </a>
    );
  }
};
