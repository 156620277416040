import React from 'react';
import Avatar, { AvatarSizesEnum } from '../avatar/avatar';
import { ButtonSocial } from '../button-social/button-social';
import { IconscoutIcons } from '../Atoms/icon/iconscout-icon';
import Text, { TextAlignments, TextTypographies, TextWeights } from '../Atoms/text/text';
import Card, { CardAlignments, CardTypes } from '../Atoms/card/card';

export interface IContactProps {
  avatarUrl: string;
  username: string;
  role: string;
  socialLink: {
    icon: IconscoutIcons;
    text: string;
    link: string;
  };
}

export const Contact = (props: IContactProps) => (
  <Card cardAlignment={CardAlignments.CENTER} cardType={CardTypes.COLUMN} className="contact">
    <Avatar imgSrc={props.avatarUrl} size={AvatarSizesEnum.Big} />
    <div className="contact__section">
      <Text
        text={props.username}
        typography={TextTypographies.BODY}
        alignment={TextAlignments.CENTER}
        weight={TextWeights.SEMIBOLD}
        className="contact__username"
      />
      <Text
        text={props.role}
        typography={TextTypographies.CONTROL_LABEL}
        alignment={TextAlignments.CENTER}
        className="contact__role"
      />
      <ButtonSocial iconName={props.socialLink.icon} textContent={props.socialLink.text} link={props.socialLink.link} />
    </div>
  </Card>
);
