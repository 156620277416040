import React from 'react';

interface ISectionTitleProps {
  title: string;
}
export function SectionTitle({ title }: ISectionTitleProps) {
  return <h2 className="section-title">{title}</h2>;
}

interface ISectionDescriptionProps {
  text: string;
}
export function SectionDescription({ text }: ISectionDescriptionProps) {
  return <p className="section-description">{text}</p>;
}
