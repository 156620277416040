import React from 'react';
import Card, { CardTypes } from '../Atoms/card/card';
import DuotoneIcon, { DuotoneIcons, DuotoneIconSizes } from '../Atoms/icon/duotone-icon';
import Title, { TitleTypes } from '../Atoms/text/title';
import Text, { TextWeights } from '../Atoms/text/text';
import List, { ListDirections } from '../Atoms/list/list';
import { ListItemTypes } from '../Atoms/list/list-item';
import { IconscoutIcons } from '../Atoms/icon/iconscout-icon';

export interface IConnectChatProps {
  title?: string;
  label?: string;
  links: {
    link: string;
    icon: IconscoutIcons;
  }[];
}

export const ConnectChat = ({ title = "Let's chat", label = "we're online", links }: IConnectChatProps) => (
  <Card className="connect-chat" cardType={CardTypes.ROW}>
    <DuotoneIcon
      className="connect-chat__messages-icon"
      iconSize={DuotoneIconSizes.BIG}
      icon={DuotoneIcons.CHAT_MESSAGES}
    />

    <div className="connect-chat__wrapper">
      <div className="connect-chat__column">
        <Title
          text={{ text: title, weight: TextWeights.BOLD }}
          titleType={TitleTypes.HEADER_3}
          className="connect-chat__title"
        />
        <Text text={label} className="connect-chat__label" />
      </div>

      <List
        className="connect-chat__icons"
        direction={ListDirections.ROW}
        listItemType={ListItemTypes.ICON}
        listItems={links.map((i) => ({
          link: i.link,
          icon: {
            icon: i.icon,
            className: 'connect-chat__icon',
          },
        }))}
      />
    </div>
  </Card>
);
