import { DuotoneIcons, DuotoneIconSizes } from './duotone-icon';
import classNames from 'classnames';
import React from 'react';
import Icon from './icon';

interface IDuotoneHoverableIconProps {
  icon: DuotoneIcons;
  iconSize: DuotoneIconSizes;
  className?: string;
}

export default function DuotoneIconHoverable(props: IDuotoneHoverableIconProps) {
  return (
    <div className={classNames('icon-hoverable', props.className)}>
      <Icon icon={props.icon} iconSize={props.iconSize} />
    </div>
  );
}
