import React from 'react';
import RelatedCasesStudyComponent from '../../containers/sections/related-cases-study-section/related-cases-study-sections';
import { IStoryblokImageAsset, IStoryblokLink } from '../types';

interface IRelatedCaseStudiesSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription?: string;
  items: {
    title: string;
    subtitle: string;
    image: IStoryblokImageAsset;
    link: IStoryblokLink;
    technologies: IStoryblokImageAsset[];
  }[];
}
export function RelatedCaseStudiesSectionStoryblokComponent({
  blok,
}: {
  blok: IRelatedCaseStudiesSectionStoryblokComponentBlok;
}) {
  return (
    <RelatedCasesStudyComponent
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      relatedCasesItem={blok.items.map((i) => ({
        imageUrl: i.image.filename,
        link: i.link?.url || i.link?.cached_url,
        title: i.title,
        subtitle: i.subtitle,
        technologies: i.technologies.map((t) => ({
          imgSrc: t.filename,
          imgAlt: t.alt,
        })),
      }))}
    />
  );
}
