export enum PagesEnum {
  GET_A_QUOTE = 'https://dazv72m711h.typeform.com/to/bTlWO2BO',
  CASES_IOT_MOBILE_APP = '/cases/application-for-smart-home-devise-connection',
  CASES_SAAS_CONSTRUCTION = '/cases/saas-construction',
  SERVICES_DEDICATED_DEVELOPMENT_TEAM = '/services/dedicated-team',
  SERVICES_HIRE_ANGULAR_DEVELOPERS = '/services/hire-angular-developers',
  SERVICES_HIRE_NODEJS_DEVELOPERS = '/services/hire-node-js-developer',
  SERVICES_STARTUP_WEB_APP_DEVELOPMENT_AGENCY = '/services/startup-web-app-development-agency',
  SERVICES_CUSTOM_SOFTWARE_DEVELOPMENT_FOR_SMALL_BUSINESS = '/services/custom-software-development-for-a-small-business',
  SERVICES_SOFTWARE_DEVELOPMENT_FOR_STARTUP = '/services/custom-software-development-services-for-startups',
  SERVICES_IOT_APPLICATION_DEVELOPMENT = '/services/iot-application-development',
}
