import React from 'react';

export interface IBenefitsRatingItem {
  icon: string;
  title: string;
  subtitle: string;
}
export interface IBenefitsSectionRatingsProps {
  items: IBenefitsRatingItem[];
}
export default function BenefitsSectionRatings({ items }: IBenefitsSectionRatingsProps) {
  return (
    <div className="benefits-section__ratings">
      {items.map((item, idx) => (
        <div key={idx} className="benefits-section__ratings__item">
          <div className="benefits-section__ratings__item__logo">
            <img src={item.icon} alt={item.title} />
          </div>
          <div className="benefits-section__ratings__item__text">
            <span className="benefits-section__ratings__item__text__title">{item.title}</span>
            <span className="benefits-section__ratings__item__text__subtitle">{item.subtitle}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
