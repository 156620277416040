import React from 'react';
import LinkTile, {
  ILinkTileProps,
} from '../../../components/link-tile/link-tile';
import TestimonialShort, {
  ITestimonialShortProps,
} from '../../../components/testimonial/testimonial-short/testimonial-short';

export interface IPrimarySectionFooterCaseProps {
  testimonial: ITestimonialShortProps;
  linkTile: ILinkTileProps;
}
export default function PrimarySectionFooterCase({
  testimonial,
  linkTile,
}: IPrimarySectionFooterCaseProps) {
  return (
    <div className="primary-section__footer primary-section__footer-case">
      <TestimonialShort {...testimonial} />
      <LinkTile {...linkTile} />
    </div>
  );
}
