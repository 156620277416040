import React from 'react';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import PrimarySection from '../../containers/sections/primary-section/primary-section';
import PrimarySectionFooterAction from '../../containers/sections/primary-section/primary-section-footer-action';
import PrimarySectionFooterCase from '../../containers/sections/primary-section/primary-section-footer-case';
import { DuotoneIcons } from '../../components/Atoms/icon/duotone-icon';
import RelatedCasesPrimarySection from '../../containers/sections/primary-section/related-cases-primary-section/related-cases-primary-section';
import { IStoryblokImageAsset, IStoryblokLink } from '../types';
import { PrimarySectionIcons } from '../../containers/sections/primary-section/primary-section-icon-mapper';

interface IPrimarySectionStoryblokComponentBlok {
  title: string;
  subtitle: string;
  icon: PrimarySectionIcons;
  footerComponent: any;
}
export function PrimarySectionStoryblokComponent({ blok }: { blok: IPrimarySectionStoryblokComponentBlok }) {
  return (
    <PrimarySection
      title={blok.title}
      subtitle={blok.subtitle}
      icon={blok.icon}
      footerComponent={blok?.footerComponent?.map((blok: any) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    />
  );
}

interface IPrimarySectionCaseViewStoryblokComponentBlok {
  title: string;
  description: string;
  developmentInfo: {
    description: string;
    title: string;
  }[];
  footerItems: {
    icon: DuotoneIcons;
    title: string;
    text: string;
  }[];
}
export function PrimarySectionCaseViewStoryblokComponent({
  blok,
}: {
  blok: IPrimarySectionCaseViewStoryblokComponentBlok;
}) {
  return (
    <RelatedCasesPrimarySection
      sectionTitle={blok.title}
      sectionDescription={blok.description}
      footerItems={blok.footerItems}
      developmentInfo={blok.developmentInfo}
    />
  );
}

interface IPrimarySectionFooterActionStoryblokComponentBlok {
  actionText: string;
  link: IStoryblokLink;
  benefits: { icon: DuotoneIcons; text: string }[];
}
export function PrimarySectionFooterActionStoryblokComponent({
  blok,
}: {
  blok: IPrimarySectionFooterActionStoryblokComponentBlok;
}) {
  return (
    <PrimarySectionFooterAction
      link={blok.link?.url}
      actionText={blok.actionText}
      benefits={blok.benefits?.map((b) => ({ icon: b.icon, text: b.text }))}
    />
  );
}

interface IPrimarySectionFooterCaseStoryblokComponentBlok {
  author: string;
  avatar: IStoryblokImageAsset;
  text: string;
  link: IStoryblokLink;
  title: string;
  subtitle: string;
}
export function PrimarySectionFooterCaseStoryblokComponent({
  blok,
}: {
  blok: IPrimarySectionFooterCaseStoryblokComponentBlok;
}) {
  return (
    <PrimarySectionFooterCase
      testimonial={{ author: blok.author, avatarUrl: blok.avatar.filename, text: blok.text }}
      linkTile={{ link: blok.link.url || blok.link.cached_url, subtitle: blok.subtitle, title: blok.title }}
    />
  );
}
