import React from 'react';
import Icon from '../Atoms/icon/icon';
import { IconscoutIcons } from '../Atoms/icon/iconscout-icon';
import Text, { TextWeights } from '../Atoms/text/text';
import Title, { TitleTypes } from '../Atoms/text/title';
import Card, { CardAlignments, CardTypes } from '../Atoms/card/card';
import classNames from 'classnames';

export interface ILinkTileProps {
  link: string;
  title: string;
  subtitle: string;
}
export default function LinkTile({ link, title, subtitle = 'look at' }: ILinkTileProps) {
  return (
    <Card link={link} cardAlignment={CardAlignments.LEFT} cardType={CardTypes.ADAPTIVE_ROW} className="link-tile">
      <Icon icon={IconscoutIcons.LAYERS} className="link-tile__left-icon" />
      <div className="link-tile__text">
        <Text className="link-tile__subtitle" text={subtitle} />
        <Title
          className="link-tile__title"
          titleType={TitleTypes.HEADER_6}
          text={{ text: title, weight: TextWeights.BOLD }}
        />
      </div>
      <div className="link-tile__arrow-down">
        <div>
          <Icon
            className={classNames('link-tile__arrow-down__gradient', 'link-tile__icon')}
            icon={IconscoutIcons.ARROW_DOWN}
          />
          <Icon
            className={classNames('link-tile__icon', 'link-tile__arrow-down__gray')}
            icon={IconscoutIcons.ARROW_DOWN}
          />
        </div>
      </div>
    </Card>
  );
}
