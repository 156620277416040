import { useEffect } from 'react';

export function useEventListener(
  eventType: string,
  callback: (event: any) => void
) {
  useEffect(() => {
    window.addEventListener(eventType, callback);
    return () => {
      window.removeEventListener(eventType, callback);
    };
  }, [callback]);
}
