import React from 'react';
import Section from '../section/section';
import ServicesBenefitsSectionHeader from './services-benefits-section-header';
import { DuotoneIconSizes, DuotoneIcons } from '../../../components/Atoms/icon/duotone-icon';
import Icon from '../../../components/Atoms/icon/icon';

interface IServicesBenefitsSecProps {
  sectionTitle: string;
  sectionDescription: string;
  benefits: {
    icon: DuotoneIcons;
    title: string;
  }[];
}
const ServicesBenefitsSection = (props: IServicesBenefitsSecProps) => {
  return (
    <section className="services-benefits-section">
      <Section title={props.sectionTitle}>
        <div className="services-benefits-section__header">
          <ServicesBenefitsSectionHeader description={props.sectionDescription} />
        </div>
        <div className="services-benefits-section__benefits">
          {props.benefits.map((item, key: number) => (
            <div className="services-benefits-section__benefit" key={key}>
              <Icon icon={item.icon} iconSize={DuotoneIconSizes.BIG} />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </Section>
    </section>
  );
};

export default ServicesBenefitsSection;
