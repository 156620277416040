import React from 'react';
import classNames from 'classnames';

export enum TextAlignments {
  RIGHT = 'right',
  CENTER = 'center',
  LEFT = 'left',
}

export enum FontStyles {
  CURSIVE = 'cursive',
  NORMAL = 'normal',
}

export enum TextTypographies {
  BODY = 'typography-body',
  BODY_SMALL = 'typography-body-small',
  CONTROL_MENU = 'typography-control-menu',
  CONTROL_LABEL = 'typography-control-label',
  CONTROL_BUTTON = 'typography-control-button',
}

export enum TextWeights {
  REGULAR = 300,
  SEMIBOLD = 500,
  BOLD = 700,
}
export interface ITextProps extends React.HTMLAttributes<HTMLElement> {
  text: string;
  alignment?: TextAlignments;
  weight?: TextWeights;
  typography?: TextTypographies;
  fontStyle?: FontStyles;
}
export default function Text(props: ITextProps) {
  return (
    <span
      {...props}
      className={classNames(
        'text',
        props.typography ? 'text--' + props.typography : '',
        props.className
      )}
      style={{
        ...props.style,
        textAlign: props.alignment,
        fontWeight: props.weight,
        fontStyle: props.fontStyle,
      }}
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  );
}
