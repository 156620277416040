import React, { ReactNode } from 'react';
import Title, { ITitleTextProps } from './title';
import IconscoutIcon, { IconscoutIcons } from '../icon/iconscout-icon';
import Icon from '../icon/icon';
import { DuotoneIcons, DuotoneIconSizes } from '../icon/duotone-icon';
import classNames from 'classnames';
import DuotoneIconHoverable from '../icon/duotone-icon-hoverable';
import Text, { ITextProps } from './text';

export enum LinkTitleArrowPositionEnum {
  Up = 'up',
  Center = 'center',
}

const renderTitle = (titleText?: ITitleTextProps, subtitle?: ITextProps): ReactNode => {
  if (!titleText && !subtitle) return <></>;

  if (titleText && subtitle)
    return (
      <span className="link-title__group">
        <Title {...titleText} />
        <Text {...subtitle} />
      </span>
    );

  return (
    <>
      {titleText && <Title {...titleText} />}
      {subtitle && <Text {...subtitle} />}
    </>
  );
};

export interface ILinkTitleProps extends React.HTMLAttributes<HTMLElement> {
  link?: string;
  titleText?: ITitleTextProps;
  subtitle?: ITextProps;
  isArrowEncircled?: boolean;
  icon?: DuotoneIcons;
  arrowPosition?: LinkTitleArrowPositionEnum;
}
export default function LinkTitle({
  className,
  link,
  titleText,
  subtitle,
  isArrowEncircled,
  icon,
  arrowPosition = LinkTitleArrowPositionEnum.Center,
}: ILinkTitleProps) {
  const Content = () => (
    <>
      {icon && <DuotoneIconHoverable icon={icon} iconSize={DuotoneIconSizes.BIG} />}
      {renderTitle(titleText, subtitle)}
      {isArrowEncircled ? (
        <div className="link-title__arrow-encircled">
          <span className="link-title__arrow-encircled__hover-box">
            <span className="link-title__arrow-encircled__hover-box-inner" />
          </span>
          <div>
            <span className="link-title__arrow-encircled__gradient">
              <Icon icon={IconscoutIcons.ARROW_UP_RIGHT} />
            </span>
            <span>
              <Icon icon={IconscoutIcons.ARROW_UP_RIGHT} />
            </span>
          </div>
        </div>
      ) : (
        <div className={classNames('link-title__arrow', `link-title__arrow--position-${arrowPosition}`)}>
          <IconscoutIcon icon={IconscoutIcons.ARROW_UP_RIGHT} />
        </div>
      )}
    </>
  );

  return link ? (
    <a className={classNames('link-title', className)} href={link}>
      <Content />
    </a>
  ) : (
    <div className={classNames('link-title', className)}>
      <Content />
    </div>
  );
}
