import { isBrowser } from './is-browser';

export function isInternalLink(linkUrl: string) {
  const currentOrigin = isBrowser() ? window.location.origin : '';

  const startsWithHttps = linkUrl.startsWith('https://') || linkUrl.startsWith('http://');
  const includesCurrentOrigin = linkUrl.includes(currentOrigin);
  const isEmailLink = linkUrl.startsWith('mailto:');
  const isPhoneLink = linkUrl.startsWith('tel:');

  if (startsWithHttps) {
    return false;
  }

  if (isEmailLink || isPhoneLink) {
    return false;
  }

  if (includesCurrentOrigin) {
    return true;
  }

  return false;
}
