import React, { Suspense } from 'react';
import classNames from 'classnames';

const PuzzleGradientSvg = React.lazy(() => import('../../../images/svg-icons/puzzle-duotone.svg'));
const MobileGradientSvg = React.lazy(() => import('../../../images/svg-icons/mobile-duotone.svg'));
const LaptopGradientSvg = React.lazy(() => import('../../../images/svg-icons/laptop-code-duotone.svg'));
const BoltGradientSvg = React.lazy(() => import('../../../images/svg-icons/bolt-duotone.svg'));
const RocketGradientSvg = React.lazy(() => import('../../../images/svg-icons/rocket-launch.svg'));
const RateStarGradientSvg = React.lazy(() => import('../../../images/svg-icons/ranking-star-duotone.svg'));
const MessagesGradientSvg = React.lazy(() => import('../../../images/svg-icons/messages.svg'));
const ArrowsToEyeGradientSvg = React.lazy(() => import('../../../images/svg-icons/arrows-to-eye-duotone.svg'));
const PenRullerSvg = React.lazy(() => import('../../../images/svg-icons/pen-ruler-duotone.svg'));
const RullerTriangleSvg = React.lazy(() => import('../../../images/svg-icons/ruller-triangle-duotone.svg'));
const EmailUsSvgIcon = React.lazy(() => import('../../../images/svg-icons/envelope-duotone.svg'));
const SetUpACallSvgIcon = React.lazy(() => import('../../../images/svg-icons/video-duotone.svg'));
const TrowelBricksSvg = React.lazy(() => import('../../../images/svg-icons/trowel-bricks-duotone.svg'));
const AboutUsSvgIcon = React.lazy(() => import('../../../images/svg-icons/about-us-duotone.svg'));
const BlogSvgIcon = React.lazy(() => import('../../../images/svg-icons/blog-duotone.svg'));
const MVPSvgIcon = React.lazy(() => import('../../../images/svg-icons/bolt-duotone.svg'));
const CareersSvgIcon = React.lazy(() => import('../../../images/svg-icons/careers-duotone.svg'));
const CasesSvgIcon = React.lazy(() => import('../../../images/svg-icons/cases-duotone.svg'));
const ContactUsSvgIcon = React.lazy(() => import('../../../images/svg-icons/contact-us-duotone.svg'));
const IoTApplicationSvgIcon = React.lazy(() => import('../../../images/svg-icons/house-signal-duotone.svg'));
const WebDevelopmentSvgIcon = React.lazy(() => import('../../../images/svg-icons/laptop-code-duotone.svg'));
const MobileDevelopmentSvgIcon = React.lazy(() => import('../../../images/svg-icons/mobile-duotone.svg'));
const DedicatedTeamSvgIcon = React.lazy(() => import('../../../images/svg-icons/puzzle-duotone.svg'));
const LogoSvgIcon = React.lazy(() => import('../../../images/svg-icons/logo.svg'));
const MessagesSvgIcon = React.lazy(() => import('../../../images/svg-icons/messages.svg'));
const CalendarSvgIcon = React.lazy(() => import('../../../images/svg-icons/calendar-star-duotone.svg'));
const PeopleSvgIcon = React.lazy(() => import('../../../images/svg-icons/people-group-duotone.svg'));
const FlagSvgIcon = React.lazy(() => import('../../../images/svg-icons/flag-swallowtail-duotone.svg'));
const SafetySvgIcon = React.lazy(() => import('../../../images/svg-icons/safety-duotone.svg'));
const OperationalEfficiencySvgIcon = React.lazy(() => import('../../../images/svg-icons/operational-efficiency-duotone.svg'));
const ReliableDataSvgIcon = React.lazy(() => import('../../../images/svg-icons/reliable-data-duotone.svg'));
const ConvenienceSvgIcon = React.lazy(() => import('../../../images/svg-icons/convenience-duotone.svg'));
const CubesSvgIcon = React.lazy(() => import('../../../images/svg-icons/cubes-duotone.svg'));
const PollPeopleSvgIcon = React.lazy(() => import('../../../images/svg-icons/poll-people-hover-duotone.svg'));
const ExpantionArrowSvgIcon = React.lazy(() => import('../../../images/svg-icons/expantion-arrow-hover-duotone.svg'));
const GamepadSvgIcon = React.lazy(() => import('../../../images/svg-icons/gamepad-hover-duotone.svg'));
const HandWithDollarSvgIcon = React.lazy(() => import('../../../images/svg-icons/hand-holding-dollar-hover-duotone.svg'));
const NameSvgIcon = React.lazy(() => import('../../../images/svg-icons/name-hover-duotone.svg'));
const LayerGroupSvgIcon = React.lazy(() => import('../../../images/svg-icons/layer-group-duotone.svg'));
const CalendarClockSvgIcon = React.lazy(() => import('../../../images/svg-icons/calendar-clock-duotone.svg'));
const BugSvgIcon = React.lazy(() => import('../../../images/svg-icons/bug-duotone.svg'));
const RocketLaunchSvgIcon = React.lazy(() => import('../../../images/svg-icons/rocket-launch-duotone.svg'));
const FileInvoiceSvgIcon = React.lazy(() => import('../../../images/svg-icons/file-invoice-duotone.svg'));
const ArrowSplitSvgIcon = React.lazy(() => import('../../../images/svg-icons/arrow-split-duotone.svg'));
const PersonFallingSvgIcon = React.lazy(() => import('../../../images/svg-icons/person-falling-duotone.svg'));
const RankingStarGradientSvg = React.lazy(() => import('../../../images/svg-icons/ranking-star-duotone.svg'));
const BinarySvgIcon = React.lazy(() => import('../../../images/svg-icons/binary-check-circle-duotone.svg'));
const HandHornsGradientSvg = React.lazy(() => import('../../../images/svg-icons/hand-horns-duotone.svg'));
const ChartMixedGradientSvg = React.lazy(() => import('../../../images/svg-icons/chart-mixed-duotone.svg'));
const TimelineArrowGradientSvg = React.lazy(() => import('../../../images/svg-icons/timeline-arrow-duotone.svg'));
const CircleNodesGradientSvg = React.lazy(() => import('../../../images/svg-icons/circle-nodes-duotone.svg'));
const KernelSvgIcon = React.lazy(() => import('../../../images/svg-icons/kernel-duotone.svg'));
const UserHeadsetSvgIcon = React.lazy(() => import('../../../images/svg-icons/user-headset-duotone.svg'));
const TeamSizeSvgIcon = React.lazy(() => import('../../../images/svg-icons/team-size-duotone.svg'));
const PulseSvgIcon = React.lazy(() => import('../../../images/svg-icons/pulse-duotone.svg'));
const IndustrySvgIcon = React.lazy(() => import('../../../images/svg-icons/industry-duotone.svg'));
const AngularSvgIcon = React.lazy(() => import('../../../images/svg-icons/angular-duotone.svg'));
const BriefcaseSvgIcon = React.lazy(() => import('../../../images/svg-icons/briefcase-duotone.svg'));
const NodeJsSvgIcon = React.lazy(() => import('../../../images/svg-icons/nodejs-duotone.svg'));

export enum DuotoneIcons {
  PUZZLE = 'puzzle',
  MOBILE = 'mobile',
  LAPTOP = 'laptop',
  BOLT = 'bolt',
  ROCKET = 'rocket',
  RATE_STAR = 'rate-star',
  MESSAGES = 'messages',
  ARROWS_TO_EYE = 'arrows-to-eye',
  PEN_RULLER = 'pen-ruller',
  BINARY = 'binary',
  CUBES = 'cubes',
  POLL_PEOPLE = 'poll-people',
  USER_HEADSET = 'user-headset',
  RULLER_TRIANGLE = 'ruller-triangle',
  SET_UP_A_CALL = 'set-up-a-call',
  EMAIL_US = 'email-us',
  TROWEL_BRICKS = 'trowel-bricks',
  ABOUT_US = 'about-us',
  BLOG = 'blog',
  MVP = 'MVP',
  CAREER = 'careers',
  CASES = 'cases',
  CONTACT_US = 'contact-us',
  IOT_APPLICATIONS = 'iot-application',
  WEB_DEVELOPMENT = 'web-development',
  MOBILE_DEVELOPMENT = 'mobile-development',
  DEDICATED_TEAM = 'dedicated-team',
  LOGO = 'logo',
  CHAT_MESSAGES = 'chat-messages',
  CALENDAR = 'calendar',
  PEOPLE = 'people',
  FLAG = 'flag',
  SAFETY = 'safety',
  OPERATIONAL_EFFICIENCY = 'operational-efficiency',
  RELIABLE_DATA = 'reliable-data',
  CONVENIENCE = 'convenience',
  KERNEL = 'kernel',
  TEAM_SIZE = 'team-size',
  PULSE = 'pulse',
  INDUSTRY = 'industry',
  EXPANTION_ARROW = 'expantion-arrow',
  GAMEPAD = 'gamepad',
  HAND_WITH_DOLLAR = 'hand-with-dollar',
  NAME = 'name',
  LAYER_GROUP = 'layer-group',
  CALENDAR_CLOCK = 'calendar-clock',
  BUG = 'bug',
  ROCKET_LAUNCH = 'rocket-launch',
  FILE_INVOICE = 'file-invoice',
  ARROW_SPLIT = 'arrow-split',
  PERSON_FALLING = 'person-falling',
  RANKING_STAR_GRAD = 'ranking-star-gard',
  HAND_HORNS_GRAD = 'HandHornsGrad',
  CHART_MIXED_GARD = 'ChartMixedGrad',
  TIMELINE_ARROW_GRAD = 'TimelineArrowGrad',
  CIRCLE_NODES_GRAD = 'CircleNodesGrad',
  ANGULAR = 'angular',
  BRIEFCASE = 'briefcase',
  NODEJS = 'nodejs',
}

const DuotoneIconsMap: Record<DuotoneIcons, any> = {
  [DuotoneIcons.PUZZLE]: PuzzleGradientSvg,
  [DuotoneIcons.MOBILE]: MobileGradientSvg,
  [DuotoneIcons.LAPTOP]: LaptopGradientSvg,
  [DuotoneIcons.BOLT]: BoltGradientSvg,
  [DuotoneIcons.ROCKET]: RocketGradientSvg,
  [DuotoneIcons.RATE_STAR]: RateStarGradientSvg,
  [DuotoneIcons.MESSAGES]: MessagesGradientSvg,
  [DuotoneIcons.ARROWS_TO_EYE]: ArrowsToEyeGradientSvg,
  [DuotoneIcons.PEN_RULLER]: PenRullerSvg,
  [DuotoneIcons.BINARY]: BinarySvgIcon,
  [DuotoneIcons.CUBES]: CubesSvgIcon,
  [DuotoneIcons.POLL_PEOPLE]: PollPeopleSvgIcon,
  [DuotoneIcons.USER_HEADSET]: UserHeadsetSvgIcon,
  [DuotoneIcons.RULLER_TRIANGLE]: RullerTriangleSvg,
  [DuotoneIcons.SET_UP_A_CALL]: SetUpACallSvgIcon,
  [DuotoneIcons.EMAIL_US]: EmailUsSvgIcon,
  [DuotoneIcons.TROWEL_BRICKS]: TrowelBricksSvg,
  [DuotoneIcons.ABOUT_US]: AboutUsSvgIcon,
  [DuotoneIcons.BLOG]: BlogSvgIcon,
  [DuotoneIcons.MVP]: MVPSvgIcon,
  [DuotoneIcons.CAREER]: CareersSvgIcon,
  [DuotoneIcons.CASES]: CasesSvgIcon,
  [DuotoneIcons.CONTACT_US]: ContactUsSvgIcon,
  [DuotoneIcons.IOT_APPLICATIONS]: IoTApplicationSvgIcon,
  [DuotoneIcons.WEB_DEVELOPMENT]: WebDevelopmentSvgIcon,
  [DuotoneIcons.MOBILE_DEVELOPMENT]: MobileDevelopmentSvgIcon,
  [DuotoneIcons.DEDICATED_TEAM]: DedicatedTeamSvgIcon,
  [DuotoneIcons.LOGO]: LogoSvgIcon,
  [DuotoneIcons.CHAT_MESSAGES]: MessagesSvgIcon,
  [DuotoneIcons.CALENDAR]: CalendarSvgIcon,
  [DuotoneIcons.PEOPLE]: PeopleSvgIcon,
  [DuotoneIcons.FLAG]: FlagSvgIcon,
  [DuotoneIcons.SAFETY]: SafetySvgIcon,
  [DuotoneIcons.OPERATIONAL_EFFICIENCY]: OperationalEfficiencySvgIcon,
  [DuotoneIcons.RELIABLE_DATA]: ReliableDataSvgIcon,
  [DuotoneIcons.CONVENIENCE]: ConvenienceSvgIcon,
  [DuotoneIcons.KERNEL]: KernelSvgIcon,
  [DuotoneIcons.TEAM_SIZE]: TeamSizeSvgIcon,
  [DuotoneIcons.PULSE]: PulseSvgIcon,
  [DuotoneIcons.INDUSTRY]: IndustrySvgIcon,
  [DuotoneIcons.EXPANTION_ARROW]: ExpantionArrowSvgIcon,
  [DuotoneIcons.GAMEPAD]: GamepadSvgIcon,
  [DuotoneIcons.HAND_WITH_DOLLAR]: HandWithDollarSvgIcon,
  [DuotoneIcons.NAME]: NameSvgIcon,
  [DuotoneIcons.LAYER_GROUP]: LayerGroupSvgIcon,
  [DuotoneIcons.CALENDAR_CLOCK]: CalendarClockSvgIcon,
  [DuotoneIcons.BUG]: BugSvgIcon,
  [DuotoneIcons.ROCKET_LAUNCH]: RocketLaunchSvgIcon,
  [DuotoneIcons.FILE_INVOICE]: FileInvoiceSvgIcon,
  [DuotoneIcons.ARROW_SPLIT]: ArrowSplitSvgIcon,
  [DuotoneIcons.PERSON_FALLING]: PersonFallingSvgIcon,
  [DuotoneIcons.RANKING_STAR_GRAD]: RankingStarGradientSvg,
  [DuotoneIcons.HAND_HORNS_GRAD]: HandHornsGradientSvg,
  [DuotoneIcons.CHART_MIXED_GARD]: ChartMixedGradientSvg,
  [DuotoneIcons.TIMELINE_ARROW_GRAD]: TimelineArrowGradientSvg,
  [DuotoneIcons.CIRCLE_NODES_GRAD]: CircleNodesGradientSvg,
  [DuotoneIcons.ANGULAR]: AngularSvgIcon,
  [DuotoneIcons.BRIEFCASE]: BriefcaseSvgIcon,
  [DuotoneIcons.NODEJS]: NodeJsSvgIcon,
};

export enum DuotoneIconSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
  LARGE = 'large',
}

export interface IDuotoneIconProps {
  icon: DuotoneIcons;
  iconSize?: DuotoneIconSizes;
  className?: string;
}

export default function DuotoneIcon(props: IDuotoneIconProps) {
  return (
    <span className={classNames('icon', 'icon--' + props.iconSize ?? 'small', props.className)}>
      <Suspense>{React.createElement(DuotoneIconsMap[props.icon])}</Suspense>
    </span>
  );
}
