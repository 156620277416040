import React from 'react';
import Section from '../../section/section';
import RelatedCasesPrimarySectionFooterAction, {
  IPrimarySectionFooterProjectInfoTile,
} from './primary-section-footer-actions';

interface IRelatedCasesPRimarySectionProps {
  sectionTitle: string;
  sectionDescription: string;
  developmentInfo: {
    title: string;
    description: string;
  }[];
  footerItems: IPrimarySectionFooterProjectInfoTile[];
}

export const RelatedCasesPrimarySection = (props: IRelatedCasesPRimarySectionProps) => (
  <section>
    <Section>
      <div className="primary-section__wrapper cases-study">
        <div className="primary-section__content">
          <h1>{props.sectionTitle}</h1>
          <p>{props.sectionDescription}</p>
        </div>
        <div className="primary-section__development-info">
          {props.developmentInfo.map((item, idx) => (
            <div key={idx} className="development-info-item">
              <h6 className="development-info-item__title">{item.title}</h6>
              <p className="development-info-item__description">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
      <RelatedCasesPrimarySectionFooterAction items={props.footerItems} />
    </Section>
  </section>
);

export default RelatedCasesPrimarySection;
