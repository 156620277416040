import React from 'react';
import { Button } from '../button/button';
import TechnologiesIcon from '../Atoms/icon/technologies-icon';

export interface ICaseCardProps {
  link: string;
  title: string;
  subtitle: string;
  imageUrl: string;
  technologies: {
    imgSrc: string;
    imgAlt: string;
  }[];
  onClick?: (...args: any[]) => void;
}

const CaseCard = (props: ICaseCardProps) => (
  <div className="case-card" onClick={props.onClick}>
    <img src={props.imageUrl} alt={props.title} className="case-card__image" />
    <div className="case-card__content">
      <Button isExtra textContent={props.title} link={props.link} />
      <p className="case-card__description">{props.subtitle}</p>
      <div className="case-card__technologies">
        {props.technologies.map((tech, idx) => (
          <TechnologiesIcon key={idx} icon={tech.imgSrc} alt={tech.imgAlt} />
        ))}
      </div>
    </div>
  </div>
);

export default CaseCard;
