import React, { ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StoryblokComponent, apiPlugin, storyblokInit, useStoryblokState } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';

import '../styles/fonts/fonts.scss';
import '../styles/main.scss';

import SvgPurpleGradient from '../styles/svg-styles/purple-gradient';
import { StoryblokComponentsEnum } from '../storyblok/types/storyblok-components-enum';
import {
  BenefitsSectionStoryblokComponent,
  ConnectSectionStoryblokComponent,
  FaqSectionStoryblokComponent,
  PrimarySectionFooterActionStoryblokComponent,
  PrimarySectionFooterCaseStoryblokComponent,
  PrimarySectionStoryblokComponent,
  ServicesSectionStoryblokComponent,
  SliderSectionStoryblokComponent,
  SpecializationsSectionStoryblokComponent,
  TechnologiesSectionStoryblokComponent,
  TestimonialsSectionStoryblokComponent,
  OrderedListSectionStoryblokComponent,
  ServicesBenefitsSectionStoryblokComponent,
  WhyCompaniesChooseUsSectionStoryblokComponent,
  SetupACallSectionStoryblokComponent,
  PrimarySectionCaseViewStoryblokComponent,
  SolutionSectionStoryblokComponent,
  RelatedCaseStudiesSectionStoryblokComponent,
  GradientBackgroundBlokStoryblokComponent,
  SimpleSectionStoryblokComponent,
  ExplanationSectionStoryblokComponent,
  ExplanationSectionArrayStoryblokComponent,
  IoTMobileDevelopmentSectionStoryblokComponent,
  HeadertoryblokComponent,
  FooterStoryblokComponent,
} from '../storyblok';
import { environment } from '../environment';

storyblokInit({
  accessToken: environment.storyblokApiAccessToken,
  use: [apiPlugin],
  components: {
    [StoryblokComponentsEnum.Header]: HeadertoryblokComponent,
    [StoryblokComponentsEnum.Footer]: FooterStoryblokComponent,
    [StoryblokComponentsEnum.FaqSection]: FaqSectionStoryblokComponent,
    [StoryblokComponentsEnum.PrimarySection]: PrimarySectionStoryblokComponent,
    [StoryblokComponentsEnum.PrimarySection_CaseView]: PrimarySectionCaseViewStoryblokComponent,
    [StoryblokComponentsEnum.PrimarySectionFooterAction]: PrimarySectionFooterActionStoryblokComponent,
    [StoryblokComponentsEnum.PrimarySectionFooterCase]: PrimarySectionFooterCaseStoryblokComponent,
    [StoryblokComponentsEnum.SliderSection]: SliderSectionStoryblokComponent,
    [StoryblokComponentsEnum.BenefitsSection]: BenefitsSectionStoryblokComponent,
    [StoryblokComponentsEnum.SpecializationsSection]: SpecializationsSectionStoryblokComponent,
    [StoryblokComponentsEnum.ServicesSection]: ServicesSectionStoryblokComponent,
    [StoryblokComponentsEnum.TechnologiesSection]: TechnologiesSectionStoryblokComponent,
    [StoryblokComponentsEnum.TestimonialsSection]: TestimonialsSectionStoryblokComponent,
    [StoryblokComponentsEnum.ConnectSection]: ConnectSectionStoryblokComponent,
    [StoryblokComponentsEnum.OrderedListSection]: OrderedListSectionStoryblokComponent,
    [StoryblokComponentsEnum.ServicesBenefitsSection]: ServicesBenefitsSectionStoryblokComponent,
    [StoryblokComponentsEnum.WhyCompaniesChooseUsSection]: WhyCompaniesChooseUsSectionStoryblokComponent,
    [StoryblokComponentsEnum.SetupACallSection]: SetupACallSectionStoryblokComponent,
    [StoryblokComponentsEnum.SolutionSection]: SolutionSectionStoryblokComponent,
    [StoryblokComponentsEnum.RelatedCaseStudiesSection]: RelatedCaseStudiesSectionStoryblokComponent,
    [StoryblokComponentsEnum.GradientBackgroundBlock]: GradientBackgroundBlokStoryblokComponent,
    [StoryblokComponentsEnum.SimpleSection]: SimpleSectionStoryblokComponent,
    [StoryblokComponentsEnum.ExplanationSection]: ExplanationSectionStoryblokComponent,
    [StoryblokComponentsEnum.ExplanationSectionArray]: ExplanationSectionArrayStoryblokComponent,
    [StoryblokComponentsEnum.IoTMobileDevelopmentSection]: IoTMobileDevelopmentSectionStoryblokComponent,
  },
});

interface IPageLayoutProps {
  children?: ReactNode;
}
const PageLayout = (props: IPageLayoutProps) => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        headerStory: storyblokEntry(full_slug: { eq: "header" }) {
          id
          uuid
          full_slug
          name
          content
          internalId
        }
        footerStory: storyblokEntry(full_slug: { eq: "footer" }) {
          id
          uuid
          full_slug
          name
          content
          internalId
        }
      }
    `
  );

  const headerStory = useStoryblokState(data.headerStory);
  const footerStory = useStoryblokState(data.footerStory);

  const Header = () => <StoryblokComponent blok={headerStory.content} />;
  const Footer = () => <StoryblokComponent blok={footerStory.content} />;

  return (
    <div className="page-layout">
      <SvgPurpleGradient />

      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

export default PageLayout;
