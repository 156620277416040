import React from 'react';
import classNames from 'classnames';
import LinkTitle from '../Atoms/text/link-title';
import Title, { TitleTypes } from '../Atoms/text/title';
import Text, { TextTypographies, TextWeights } from '../Atoms/text/text';
import Card, { CardAlignments, CardTypes } from '../Atoms/card/card';

export interface ISpecializationTileProps {
  title: string;
  text: string;
  link?: string;
}
export default function SpecializationTile({ title, text, link }: ISpecializationTileProps) {
  return (
    <Card
      link={link}
      className={classNames('specialization-tile ', !link && 'specialization-tile-arrowless')}
      cardAlignment={CardAlignments.LEFT}
      cardType={CardTypes.COLUMN}>
      <div className="specialization-tile__header">
        {link ? (
          <LinkTitle
            className="specialization-tile__header"
            titleText={{
              titleType: TitleTypes.HEADER_3,
              text: { text: title, weight: TextWeights.SEMIBOLD },
            }}
            isArrowEncircled={true}
          />
        ) : (
          <Title titleType={TitleTypes.HEADER_3} text={{ text: title, weight: TextWeights.SEMIBOLD }} />
        )}
      </div>
      <Text text={text} typography={TextTypographies.BODY_SMALL} className="specialization-tile__text" />
    </Card>
  );
}
