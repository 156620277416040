import classNames from 'classnames';
import React, { useState } from 'react';
import Section from '../section/section';
import OrderedListSectionHeader, { OrderedListIconsEnum } from './ordered-list-section-header';

export interface IOrderedListSectionListItem {
  title: string;
  description?: string;
}

export enum OrderedListSectionTypesEnum {
  Primary = 'primary',
  Inline = 'inline',
  Gradient = 'gradient',
}
const OrderedListSectionTypesEnumMap: Record<string, OrderedListSectionTypesEnum> = {
  primary: OrderedListSectionTypesEnum.Primary,
  inline: OrderedListSectionTypesEnum.Inline,
  gradient: OrderedListSectionTypesEnum.Gradient,
};

export interface IOrderedListSectionProps {
  showAll?: boolean;
  title?: string;
  description?: string | string[];
  type?: OrderedListSectionTypesEnum | string;
  headerIcon?: OrderedListIconsEnum;
  listDescription?: string;
  listTitle: string;
  list: IOrderedListSectionListItem[];
  className?: string;
}

const getListType = (type: OrderedListSectionTypesEnum | string): OrderedListSectionTypesEnum => {
  if (typeof type === 'string') return OrderedListSectionTypesEnumMap[type.toLowerCase()];

  return type;
};

export default function OrderedListSection({
  title,
  description,
  listTitle,
  listDescription,
  list,
  showAll = false,
  type = OrderedListSectionTypesEnum.Primary,
  headerIcon,
  className,
}: IOrderedListSectionProps) {
  const [activeItem, setActiveItem] = useState(0);

  const onItemClickHandler = (itemIdx: number) => {
    if (!showAll) {
      setActiveItem(itemIdx);
    }
  };

  return (
    <section className={classNames(className, 'ordered-list-section', `ordered-list-section--${getListType(type)}`)}>
      <Section title={title} description={description}>
        <div className="ordered-list-section__wrapper">
          <OrderedListSectionHeader title={listTitle} description={listDescription} icon={headerIcon} />

          <div className="ordered-list-section__list">
            {list.map((item, idx) => (
              <div
                key={idx}
                className={classNames(
                  'ordered-list-section__list__item',
                  (showAll || idx === activeItem) && 'ordered-list-section__list__item--active'
                )}
                onClick={() => onItemClickHandler(idx)}>
                <div className="ordered-list-section__list__item__number">{idx + 1}</div>
                <div className="ordered-list-section__list__item__content">
                  {type === OrderedListSectionTypesEnum.Inline ? <p>{item.title}</p> : <h3>{item.title}</h3>}
                  {item.description && (
                    <div>
                      <p>{item.description}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </section>
  );
}
