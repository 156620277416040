import React from 'react';
import { ConnectSection } from '../../containers/sections/section-connect/connect-section';
import { IContactProps } from '../../components/contact/contact';
import { IconscoutIcons } from '../../components/Atoms/icon/iconscout-icon';
import { IConnectTileProps } from '../../components/connect-social/connect-social';
import { IConnectChatProps } from '../../components/connect-chat/connect-chat';
import { IConnectTileStorybookComponentBlok } from './connect-tile';
import { IStoryblokLink } from '../types';

interface IConnectSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  contacts: {
    username: string;
    role: string;
    avatar: { filename: string };
    socialLink: IStoryblokLink;
    socialLinkIcon: IconscoutIcons;
    socialLinkText: string;
  }[];
  methods: IConnectTileStorybookComponentBlok[];
  chatBlock: {
    label: string;
    title: string;
    links: {
      icon: IconscoutIcons;
      link: IStoryblokLink;
    }[];
  }[];
}

export function ConnectSectionStoryblokComponent({ blok }: { blok: IConnectSectionStoryblokComponentBlok }) {
  const contacts: IContactProps[] = blok.contacts.map((c) => ({
    avatarUrl: c.avatar.filename,
    role: c.role,
    username: c.username,
    socialLink: {
      text: c.socialLinkText,
      icon: c.socialLinkIcon,
      link: c.socialLink.url || c.socialLink.cached_url,
    },
  }));

  const methods: IConnectTileProps[] = blok.methods.map((m) => ({
    description: m.description,
    headlineContent: m.headlineContent,
    icon: m.icon,
    buttonText: m.buttonText,
    link: m.link?.cached_url || m.link?.url,
  }));

  const chatBlock: IConnectChatProps | undefined = blok.chatBlock[0]
    ? {
        title: blok.chatBlock[0].title,
        label: blok.chatBlock[0].label,
        links: blok.chatBlock[0].links.map((l) => ({ icon: l.icon, link: l.link.url || l.link.cached_url })),
      }
    : undefined;

  return (
    <ConnectSection
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      contacts={contacts}
      methods={methods}
      chatBlock={chatBlock}
    />
  );
}
