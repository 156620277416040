import React from 'react';
import { Button } from '../../../components/button/button';
import DuotoneIcon, { DuotoneIcons } from '../../../components/Atoms/icon/duotone-icon';

interface IBenefit {
  icon: DuotoneIcons;
  text: string;
}

export interface IPrimarySectionFooterActionProps {
  benefits?: IBenefit[];
  actionText: string;
  link: string;
}

export default function PrimarySectionFooterAction(props: IPrimarySectionFooterActionProps) {
  return (
    <div className="primary-section__footer primary-section__footer-action">
      {!!props.benefits?.length && (
        <div className="primary-section__footer-action__items">
          {props.benefits.map((item, idx) => (
            <div key={idx} className="primary-section__footer-action__items__item">
              <div className="primary-section__footer-action__items__item__icon">
                <DuotoneIcon icon={item.icon} />
              </div>
              <div className="primary-section__footer-action__items__item__text">{item.text}</div>
            </div>
          ))}
        </div>
      )}

      <Button isPrimary textContent={props.actionText} link={props.link} />
    </div>
  );
}
