import { IStoryblokLink } from '../types';

export const getLinkUrl = (link: IStoryblokLink) => {
  const linkValue = link.url || link.cached_url;

  if (link.linktype === 'story') {
    return '/' + linkValue;
  }

  return linkValue;
};
