import classNames from 'classnames';
import React, { ReactNode } from 'react';
import CSS from 'csstype';
import {
  SectionDescription,
  SectionTitle,
} from '../section-typography/section-typography';

export enum SectionTypesEnum {
  Primary = 'primary',
  Secondary = 'secondary',
}

const renderDescription = (
  description?: string | string[]
): JSX.Element | JSX.Element[] => {
  if (!description) return <></>;

  if (!Array.isArray(description))
    return <SectionDescription text={description} />;

  return description.map((item, idx) => (
    <SectionDescription key={idx} text={item} />
  ));
};

export interface ISectionProps {
  className?: string;
  type?: SectionTypesEnum;
  children?: ReactNode;
  style?: CSS.Properties;
  title?: string;
  description?: string | string[];
}
export default function Section({
  className,
  style,
  type = SectionTypesEnum.Primary,
  children,
  title,
  description,
}: ISectionProps) {
  return (
    <div
      className={classNames('section', `section--${type}`, className)}
      style={style}>
      {(title || description) && (
        <div className="section-header">
          {title && <SectionTitle title={title} />}
          {renderDescription(description)}
        </div>
      )}
      {children}
    </div>
  );
}
