import React from 'react';
import Section, { SectionTypesEnum } from '../../containers/sections/section/section';

interface ISimpleSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  type: SectionTypesEnum;
}

export function SimpleSectionStoryblokComponent({ blok }: { blok: ISimpleSectionStoryblokComponentBlok }) {
  return <Section title={blok.sectionTitle} description={blok.sectionDescription} type={blok.type} />;
}
