import React from 'react';
import ServiceTile, { IServiceTileProps } from '../../../components/service-tile/service-tile';
import { Button } from '../../../components/button/button';
import Section from '../section/section';

interface IWhyChooseUsProps {
  sectionTitle: string;
  sectionDescription?: string;
  serviceTiles: IServiceTileProps[];
  buttonText: string;
  link: string;
}

const WhyCompaniesChooseUsSection = (props: IWhyChooseUsProps) => (
  <section className="why-choose-us-section">
    <Section title={props.sectionTitle} description={props.sectionDescription}>
      <div className="why-choose-us-section__service-tiles">
        {props.serviceTiles.map((tile: IServiceTileProps, key: number) => (
          <ServiceTile key={key} {...tile} />
        ))}
      </div>
      <Button textContent={props.buttonText} link={props.link} isPrimary />
    </Section>
  </section>
);

export default WhyCompaniesChooseUsSection;
