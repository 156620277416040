import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../Atoms/icon/icon';
import { IconscoutIcons } from '../Atoms/icon/iconscout-icon';

export interface IPaginatorProps {
  itemsCount: number;
  currentItem?: number;
  onNextClick?: (...args: any[]) => void;
  onPreviousClick?: (...args: any[]) => void;
}

export const Paginator = (props: IPaginatorProps): JSX.Element => {
  const [currentItem, setCurrentItem] = useState(0);
  const items: number[] = Array.from(
    { length: props.itemsCount },
    (value, idx) => idx
  );

  useEffect(() => {
    const newCurrentItem = props.currentItem;
    if (newCurrentItem === undefined) {
      return;
    }

    const isDifference = newCurrentItem !== currentItem;
    const isNotOutOfRange =
      newCurrentItem < props.itemsCount && newCurrentItem >= 0;

    if (isDifference && isNotOutOfRange) {
      setCurrentItem(newCurrentItem);
    }
  }, [props.currentItem]);

  const handleNextClick = ({ onNextClick, itemsCount }: IPaginatorProps) => {
    if (onNextClick) onNextClick();

    setCurrentItem((prev) =>
      prev + 1 > itemsCount - 1 ? itemsCount - 1 : prev + 1
    );
  };

  const handlePreviousClick = ({ onPreviousClick }: IPaginatorProps) => {
    if (onPreviousClick) onPreviousClick();

    setCurrentItem((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
  };

  return (
    <div className="paginator">
      {items.map((element: number, idx: number) => (
        <div
          key={idx}
          className={classNames(
            'paginator__item',
            element === currentItem && 'paginator__item-active'
          )}></div>
      ))}
      <div className="paginator__pagination-buttons">
        <button
          onClick={() => handlePreviousClick(props)}
          className="paginator__pagination-button paginator__pagination-button-previous">
          <Icon icon={IconscoutIcons.ARROW_LEFT} />
        </button>
        <button
          className="paginator__pagination-button paginator__pagination-button-next"
          onClick={() => handleNextClick(props)}>
          <span>
            <Icon icon={IconscoutIcons.ARROW_RIGHT} />
          </span>
        </button>
      </div>
    </div>
  );
};
