import React from 'react';
import ServicesBenefitsSection from '../../containers/sections/services-benefits-section/services-benefits-section';
import { DuotoneIcons } from '../../components/Atoms/icon/duotone-icon';

interface IServicesBenefitsSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  benefits: {
    title: string;
    icon: DuotoneIcons;
  }[];
}

export function ServicesBenefitsSectionStoryblokComponent({
  blok,
}: {
  blok: IServicesBenefitsSectionStoryblokComponentBlok;
}) {
  return (
    <ServicesBenefitsSection
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      benefits={blok.benefits}
    />
  );
}
