import React, { useEffect, useRef } from 'react';
import SliderSectionInfoBlock, { ISliderSectionInfoBlockProps } from './slider-section-info-block';

interface ISliderSectionInfoBlockWrapperProps {
  infoBlocks: ISliderSectionInfoBlockProps[];
  sectionTitle?: string;
  changeActiveInfoBlock: (idx: number) => void;
}
export default function SliderSectionInfoBlockWrapper({
  infoBlocks,
  sectionTitle,
  changeActiveInfoBlock,
}: ISliderSectionInfoBlockWrapperProps) {
  const infoBlocksRefs = [...new Array(infoBlocks.length)].map((i) => {
    return useRef(null);
  });

  // Observe which info block is active (is more visible)
  useEffect(() => {
    if (infoBlocksRefs.some((v) => !v.current)) {
      return;
    }

    infoBlocksRefs.forEach((block, idx) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries?.forEach((entry) => {
            if (entry.intersectionRatio >= 0.5) {
              changeActiveInfoBlock(idx);
            }
          });
        },
        {
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        }
      );

      if (block.current) {
        observer.observe(block.current);
      }
    });
  }, [infoBlocksRefs]);

  return (
    <div className="slider-section__info-blocks-wrapper">
      {infoBlocks.map((block, idx) => (
        <div className="slider-section__info-block-wrapper" ref={infoBlocksRefs[idx]} key={idx}>
          {idx === 0 && sectionTitle && <h3>{sectionTitle}</h3>}
          <SliderSectionInfoBlock {...block} />
        </div>
      ))}
    </div>
  );
}
