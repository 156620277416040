import React from 'react';
import SliderSection from '../../containers/sections/slider-section/slider-section';
import { IStoryblokImageAsset, IStoryblokLink } from '../types';
import { ScreenSizesEnum, useBreakpoint } from '../../hooks/use-breakpoint';

interface ISliderSectionStoryblokComponentBlokSlide {
  name: string;
  title: string;
  description: string;
  tag: string;
  backgroundColor: string;
  location: string;
  technologies: IStoryblokImageAsset[];
  footerText: string;
  mobileImage: IStoryblokImageAsset;
  desktopImage: IStoryblokImageAsset;
  link: IStoryblokLink;
}
interface ISliderSectionStoryblokComponentBlok {
  sectionTitle: string;
  slides: ISliderSectionStoryblokComponentBlokSlide[];
}
export function SliderSectionStoryblokComponent({ blok }: { blok: ISliderSectionStoryblokComponentBlok }) {
  const isMobile = useBreakpoint(ScreenSizesEnum.Mobile);

  const getSlideImage = (slide: ISliderSectionStoryblokComponentBlokSlide) => {
    const imageAsset = isMobile ? slide.mobileImage || slide.desktopImage : slide.desktopImage || slide.mobileImage;
    return { src: imageAsset.filename, alt: imageAsset.alt || '' };
  };

  return (
    <SliderSection
      sectionTitle={blok.sectionTitle}
      slides={blok.slides.map((slide) => ({
        image: getSlideImage(slide),
        backgroundColor: slide.backgroundColor,
        info: {
          link: slide.link.cached_url || slide.link.url,
          title: slide.title,
          description: slide.description,
          name: slide.name,
          location: { text: slide.location },
          footerText: slide.footerText,
          technologies: slide.technologies.map((t) => ({ alt: t.alt, src: t.filename })),
          tag: slide.tag ? { text: slide.tag } : undefined,
        },
      }))}
    />
  );
}
