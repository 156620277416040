import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../../components/Atoms/icon/icon';
import { IconscoutIcons } from '../../../../components/Atoms/icon/iconscout-icon';

export interface IAccordionItem {
  id: string;
  title: string;
  content: string;
  isActive: boolean;
}

export interface IFaqSectionAccordionProps {
  items: IAccordionItem[];
  onItemClick?: (...args: any[]) => void;
}

export const FaqSectionAccordion = (props: IFaqSectionAccordionProps) => {
  const [accordion, setAccordion] = useState(props.items);
  const handleClick = (accordionItemId: string): void => {
    if (props.onItemClick) {
      props.onItemClick();
    }

    setAccordion((prev) =>
      prev.map((element) => ({
        ...element,
        isActive: element.id === accordionItemId ? !element.isActive : false,
      }))
    );
  };

  return (
    <div className="faq-section-accordion">
      {accordion.map((element) => (
        <div
          key={element.id}
          className={classNames('accordion-item', element.isActive && 'accordion-item-active')}
          onClick={() => handleClick(element.id)}>
          <h3 className="accordion-item__title">
            <span>{element.title}</span>
            <Icon icon={IconscoutIcons.ANGLE_DOWN} />
          </h3>

          <div className="accordion-item__content" dangerouslySetInnerHTML={{ __html: element.content }}></div>
        </div>
      ))}
    </div>
  );
};
