import classNames from 'classnames';
import React from 'react';

interface ISliderSectionSliderThumbsProps {
  count: number;
  activeThumb: number;
}
export default function SliderSectionSliderThumbs({
  activeThumb,
  count,
}: ISliderSectionSliderThumbsProps) {
  return (
    <div className="slider-section__slider__thumbs">
      {new Array(count).fill(null).map((i, idx) => (
        <span
          key={idx}
          className={classNames(
            'slider-section__slider__thumbs__item',
            idx + 1 === activeThumb &&
              'slider-section__slider__thumbs__item--active'
          )}
        />
      ))}
    </div>
  );
}
