import React from 'react';
import Icon from '../../../components/Atoms/icon/icon';
import {
  DuotoneIcons,
  DuotoneIconSizes,
} from '../../../components/Atoms/icon/duotone-icon';

export interface IBenefitItem {
  icon: DuotoneIcons;
  title: string;
  text: string;
}

interface IBenefitsSectitonBenefitsProps {
  benefits: IBenefitItem[];
}
export default function BenefitsSectitonBenefits({
  benefits,
}: IBenefitsSectitonBenefitsProps) {
  return (
    <div className="benefits-section__benefits">
      {benefits.map((benefit, idx) => (
        <div key={idx} className="benefits-section__benefits__item">
          <div className="benefits-section__benefits__item__icon">
            <Icon icon={benefit.icon} iconSize={DuotoneIconSizes.BIG} />
          </div>
          <div className="benefits-section__benefits__item__content">
            <h3>{benefit.title}</h3>
            <p>{benefit.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
