import React, { useEffect } from 'react';
import Typed from 'typed.js';

interface IWordEmphasisProps {
  words: string[];
}

const EmphasizedWordsAnimated = ({ words }: IWordEmphasisProps) => {
  const el = React.useRef(null);
  const typed = React.useRef<Typed | null>(null);

  useEffect(() => {
    const options = {
      strings: words,
      typeSpeed: 200,
      backSpeed: 50,
      loop: true,
    };

    typed.current = new Typed(el.current, options);

    return () => {
      if (typed.current) typed.current.destroy();
    };
  }, []);

  return <b ref={el}></b>;
};

export default EmphasizedWordsAnimated;
