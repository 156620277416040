import { IHeaderNavigation } from '../header';
import { IHeaderNavigationMenu } from '../header-menu/header-menu';

export function mapDesktopHeaderToMobile(headerNavigation: IHeaderNavigation[]): IHeaderNavigationMenu[] {
  return headerNavigation.reduce((acc: IHeaderNavigationMenu[], nav: IHeaderNavigation) => {
    if (nav.menues) {
      acc.push(...nav.menues);
    }
    return acc;
  }, []);
}
