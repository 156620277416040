import React from 'react';
import { Filter } from '../../../components/filter/filter';
import { ITextProps } from '../../../components/Atoms/text/text';

interface ITechnologiesSectionFiltersProps {
  filters: ITextProps[];
  activeFilter: ITextProps;
  onFilterClick: (filter: string) => void;
}
export default function TechnologiesSectionFilters({
  filters,
  activeFilter,
  onFilterClick,
}: ITechnologiesSectionFiltersProps) {
  return (
    <div className="technologies-section__filters">
      {filters.map((filter, idx) => (
        <Filter
          key={idx}
          text={filter}
          isActive={filter.text === activeFilter.text}
          onClick={() => onFilterClick(filter.text)}
          notSwitchOffByClick
        />
      ))}
    </div>
  );
}
