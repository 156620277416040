import React from 'react';
import Section, { SectionTypesEnum } from '../section/section';
import SpecializationTile, {
  ISpecializationTileProps,
} from '../../../components/specialization-tile/specialization-tile';
import CSS from 'csstype';

export interface ISpecializationSectionProps {
  sectionTitle: string;
  items: ISpecializationTileProps[];
  sectionDescription?: string;
  className?: string;
  hasGradientBackground?: boolean;
}

const getGradientBackground = (hasGradientBg: boolean): CSS.Properties =>
  hasGradientBg
    ? {
        background: `transparent url('/basicBg.png')`,
      }
    : {};

export const SpecializationsSection = (props: ISpecializationSectionProps) => (
  <section className="specializations-section" style={getGradientBackground(!!props.hasGradientBackground)}>
    <Section className={props.className} type={SectionTypesEnum.Secondary}>
      <div className="specializations-section__wrapper">
        <div className="specializations-section__header">
          <h2 className="section-title">{props.sectionTitle}</h2>
          {props.sectionDescription && <p className="section-description">{props.sectionDescription}</p>}
        </div>
        <div className="specializations-section__specialization-tiles">
          {props.items.map((item, idx) => (
            <SpecializationTile key={idx} {...item} />
          ))}
        </div>
      </div>
    </Section>
  </section>
);
