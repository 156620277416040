import Text, { TextTypographies, TextWeights } from '../../Atoms/text/text';
import React from 'react';
import Avatar from '../../avatar/avatar';

export interface ITestimonialShortProps {
  avatarUrl: string;
  text: string;
  author: string;
}
export default function TestimonialShort({ avatarUrl, text, author }: ITestimonialShortProps) {
  return (
    <div className="testimonial testimonial-short">
      <Avatar imgSrc={avatarUrl} />
      <div className="testimonial-short__content">
        <Text text={text} typography={TextTypographies.BODY_SMALL} className="testimonial-short__text" />
        <Text
          text={author}
          typography={TextTypographies.BODY_SMALL}
          weight={TextWeights.BOLD}
          className="testimonial-short__author"
        />
      </div>
    </div>
  );
}
