import React from 'react';
import Section from '../section/section';
import { ConnectChat, IConnectChatProps } from '../../../components/connect-chat/connect-chat';
import { ConnectTile } from '../../../components/connect-social/connect-social';
import { SectionTitle, SectionDescription } from '../section-typography/section-typography';
import { ContactCard } from './contact-card/contact-card';
import { IContactProps } from '../../../components/contact/contact';
import { IConnectTileProps } from '../../../components/connect-social/connect-social';

export interface IConnectSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  contacts: IContactProps[];
  methods: IConnectTileProps[];
  chatBlock?: IConnectChatProps;
}

export const ConnectSection = ({
  sectionTitle,
  sectionDescription,
  contacts,
  methods,
  chatBlock,
}: IConnectSectionProps): JSX.Element => (
  <section className="connect-section" id="connect-section">
    <Section>
      <div className="connect-section__wrapper">
        <div className="connect-section__left">
          <SectionTitle title={sectionTitle} />
          <SectionDescription text={sectionDescription} />
          <div className="connect-section__social-tiles">
            {methods.map((i, idx) => (
              <ConnectTile
                key={idx}
                icon={i.icon}
                headlineContent={i.headlineContent}
                description={i.description}
                buttonText={i.buttonText}
                link={i.link}
              />
            ))}

            {chatBlock && <ConnectChat title={chatBlock.title} label={chatBlock.label} links={chatBlock.links} />}
          </div>
        </div>
        <ContactCard contacts={contacts} className="connect-section__contact-section-right" />
      </div>
    </Section>
  </section>
);
