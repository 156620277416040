import React, { useState } from 'react';
import Section from '../section/section';
import TechnologiesSectionFilters from './technologies-section-filters';
import TechnologiesSectionList, {
  ITechnologiesListItem,
} from './technologies-section-list';
import { ITextProps } from '../../../components/Atoms/text/text';

export interface ITechnologiesSectionProps {
  title: string;
  description: string;
  filters: ITextProps[];
  technologies: ITechnologiesListItem[];
}
export default function TechnologiesSection({
  title,
  description,
  filters,
  technologies,
}: ITechnologiesSectionProps) {
  const [activeFilter, setActiveFilter] = useState(filters[0].text);

  return (
    <section className="technologies-section">
      <Section title={title} description={description}>
        <TechnologiesSectionFilters
          filters={filters}
          activeFilter={{ text: activeFilter }}
          onFilterClick={(filter) => setActiveFilter(filter)}
        />
        <TechnologiesSectionList
          technologies={technologies}
          activeFilter={activeFilter}
        />
      </Section>
    </section>
  );
}
