import classNames from 'classnames';
import React from 'react';
import ServiceTile, { IServiceTileProps } from '../../../components/service-tile/service-tile';
import Section from '../section/section';

export enum ServicesSectionTypesEnum {
  Row = 'row',
  Grid = 'grid',
}

export interface IServicesSectionProps {
  title: string;
  description?: string;
  services: IServiceTileProps[];
  type?: ServicesSectionTypesEnum;
  grayBackground?: boolean;
}

export default function ServicesSection({
  title,
  description,
  services,
  grayBackground,
  type = ServicesSectionTypesEnum.Grid,
}: IServicesSectionProps) {
  return (
    <section className={classNames('services-section', grayBackground && 'services-section--gray-background')}>
      <Section title={title} description={description}>
        <div className={classNames('services-section__tiles', `services-section__tiles--${type}`)}>
          {services.map((service, idx) => (
            <ServiceTile key={idx} {...service} />
          ))}
        </div>
      </Section>
    </section>
  );
}
