import React, { useState } from 'react';
import Section from '../section/section';
import { ISliderSectionInfoBlockProps } from './slider-section-inform-block/slider-section-info-block';
import { ScreenSizeCheckType, useBreakpoint } from '../../../hooks/use-breakpoint';
import SliderSectionMobileLayout from './layouts/slider-section-mobile-layout';
import SliderSectionDesktopLayout from './layouts/slider-sesction-desktop-layout';

export interface ISliderSectionSlider {
  info: ISliderSectionInfoBlockProps;
  image: {
    src: string;
    alt: string;
  };
  backgroundColor?: string;
}

export interface ISliderSectionProps {
  sectionTitle: string;
  slides: ISliderSectionSlider[];
}
export default function SliderSection({ slides, sectionTitle }: ISliderSectionProps) {
  const MOBILE_VERSION_BREAKPOINT = 920;
  const isDesktopVersion = useBreakpoint(MOBILE_VERSION_BREAKPOINT, ScreenSizeCheckType.WindowInnerWidth);

  const [backgroundColor, setBackgroundColor] = useState(slides[0].backgroundColor);

  const onSlideChangeHandler = (slideIdx: number) => {
    setBackgroundColor(slides[slideIdx].backgroundColor);
  };

  return (
    <section className="slider-section" style={{ background: backgroundColor }}>
      <Section title={!isDesktopVersion ? sectionTitle : undefined}>
        {isDesktopVersion === true && (
          <SliderSectionDesktopLayout
            sectionTitle={isDesktopVersion ? sectionTitle : undefined}
            slides={slides}
            onSlideChange={onSlideChangeHandler}
          />
        )}
        {isDesktopVersion === false && <SliderSectionMobileLayout slides={slides} />}
      </Section>
    </section>
  );
}
