import React from 'react';
import { ISbRichtext, renderRichText } from 'gatsby-source-storyblok';
import { FAQSection } from '../../containers/sections/faq-section/faq-section';
import { IAccordionItem } from '../../containers/sections/faq-section/accordion/faq-section-accordion';

interface IFaqSectionStoryblokComponentBlok {
  sectionTitle: string;
  accordionItems: {
    title: string;
    content: ISbRichtext;
    _uid: string;
  }[];
}
export function FaqSectionStoryblokComponent({ blok }: { blok: IFaqSectionStoryblokComponentBlok }) {
  const accordionItems: IAccordionItem[] = blok.accordionItems.map((i, idx) => ({
    content: renderRichText(i.content),
    title: i.title,
    id: i._uid,
    isActive: idx === 0,
  }));

  return <FAQSection sectionTitle={blok.sectionTitle} accordionItems={accordionItems} />;
}
