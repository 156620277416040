import React, { useState } from 'react';
import classNames from 'classnames';
import Text, { ITextProps } from '../Atoms/text/text';
import IconscoutIcon, { IconscoutIcons } from '../Atoms/icon/iconscout-icon';

export interface ITagProps {
  text: ITextProps;
  iconName?: IconscoutIcons;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: (...args: any[]) => void;
  className?: string;
}

export const Tag = (props: ITagProps): JSX.Element => {
  const initialActiveState: boolean = props.isActive ?? false;
  const [isActive, setIsActive] = useState(initialActiveState);

  const handleClick = ({ onClick }: ITagProps) => {
    if (onClick) onClick();

    setIsActive((prev) => !prev);
  };

  return (
    <button
      className={classNames(
        'tag',
        isActive && 'tag-active',
        props.iconName && 'tag-with-icon',
        props.className
      )}
      disabled={!!props.isDisabled}
      onClick={() => handleClick(props)}>
      {props.iconName && <IconscoutIcon icon={IconscoutIcons.MAP_MARKER} />}
      <Text {...props.text} className="tag__title" />
    </button>
  );
};
