import React from 'react';
import Section from '../section/section';
import LogoSvgIcon from '../../../images//svg-icons/icon.svg';
import ServicesSection, { ServicesSectionTypesEnum } from '../services-section/services-section';
import { IServiceTileProps } from '../../../components/service-tile/service-tile';

interface IIoTMobileDevSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  cardsTitle: string;
  cards: IServiceTileProps[];
  image?: {
    src: string;
    alt: string;
  };
}

const IoTMobileAppDevelopmentSection = (props: IIoTMobileDevSectionProps) => (
  <section className="iot-mobile-development-section">
    <Section>
      <div className="iot-mobile-development-section__header">
        <div className="iot-mobile-development-section__header-group">
          <h2 className="iot-mobile-development-section__title">{props.sectionTitle}</h2>
          <p className="iot-mobile-development-section__description">{props.sectionDescription}</p>
        </div>
        {props.image && (
          <div className="iot-mobile-development-section__img-wrapper">
            <LogoSvgIcon className="iot-mobile-development-section__logo" />
            <img className="iot-mobile-development-section__phone-img" src={props.image.src} alt={props.image.alt} />
          </div>
        )}
      </div>
      <ServicesSection title={props.cardsTitle} services={props.cards} type={ServicesSectionTypesEnum.Row} />
    </Section>
  </section>
);

export default IoTMobileAppDevelopmentSection;
