import React from 'react';
import ListItem, { IListItemContentProps, ListItemTypes } from './list-item';
import classNames from 'classnames';

export enum ListDirections {
  ROW = 'row',
  COLUMN = 'column',
}
export interface IListProps extends React.HTMLAttributes<HTMLElement> {
  direction: ListDirections;
  listItemType: ListItemTypes;
  listItems: IListItemContentProps[];
  shouldDisplayKey?: boolean;
}

export default function List(props: IListProps) {
  return (
    <ul className={classNames('list', `list--${props.direction}`, props.className)} style={props.style}>
      {props.listItems.map((item: IListItemContentProps, idx: number) => (
        <ListItem
          key={idx}
          itemId={idx + 1}
          className={(props.className ?? 'list') + '-item'}
          listType={props.listItemType}
          shouldDisplayKey={props.shouldDisplayKey}
          content={item}
        />
      ))}
    </ul>
  );
}
