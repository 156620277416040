import React from 'react';
import WhyCompaniesChooseUsSection from '../../containers/sections/why-companies-choose-us/why-companies-choose-us';
import { IServiceTileStorybookComponentBlok } from './service-tile';
import { IStoryblokLink } from '../types';

interface IWhyCompaniesChooseUsSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  buttonText: string;
  benefits: IServiceTileStorybookComponentBlok[];
  buttonLink: IStoryblokLink;
}

export function WhyCompaniesChooseUsSectionStoryblokComponent({
  blok,
}: {
  blok: IWhyCompaniesChooseUsSectionStoryblokComponentBlok;
}) {
  return (
    <WhyCompaniesChooseUsSection
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription || undefined}
      buttonText={blok.buttonText}
      serviceTiles={blok.benefits.map((i) => ({ ...i, link: i.link.url }))}
      link={blok.buttonLink?.cached_url || blok.buttonLink?.url}
    />
  );
}
