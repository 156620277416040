import React from 'react';
import Section from '../section/section';
import OrderedListSection, { IOrderedListSectionProps } from '../ordered-list-section/ordered-list-section';
import Slider from '../../../components/slider/slider';
import ProtoQLogoSvg from '../../../images/svg-icons/protoq_logo_transparent_gradient.svg';

interface ISolutionServiceProps {
  slides: JSX.Element[];
  list: IOrderedListSectionProps;
  footer: {
    title: string;
    description: string;
  };
}
export default function SolutionSection({ slides, list, footer }: ISolutionServiceProps) {
  return (
    <div className="solution-section">
      <OrderedListSection {...list} />
      <Section>
        <Slider slides={slides} />
        <ProtoQLogoSvg className="solution-section__logo" />
      </Section>
      <Section className="solution-section__footer" {...footer} />
    </div>
  );
}
