import React from 'react';
import SolutionSection from '../../containers/sections/solution-section/solution-section';
import { OrderedListSectionTypesEnum } from '../../containers/sections/ordered-list-section/ordered-list-section';

import { IStoryblokImageAsset } from '../types';

interface ISolutionSectionStoryblokComponentBlok {
  images: IStoryblokImageAsset[];
  orderedListTitle: string;
  orderedListText: string;
  orderedListItems: { text: string }[];
  footerTitle: string;
  footerText: string;
}
export function SolutionSectionStoryblokComponent({ blok }: { blok: ISolutionSectionStoryblokComponentBlok }) {
  return (
    <SolutionSection
      list={{
        listTitle: blok.orderedListTitle,
        listDescription: blok.orderedListText,
        list: blok.orderedListItems.map((i) => ({ title: i.text })),
        type: OrderedListSectionTypesEnum.Inline,
        showAll: true,
      }}
      footer={{
        title: blok.footerTitle,
        description: blok.footerText,
      }}
      slides={blok.images.map((i) => (
        <img key={i.id} src={i.filename} alt={i.alt} />
      ))}
    />
  );
}
