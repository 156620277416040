import {
  ConnectTile,
  IConnectTileProps,
} from '../../../components/connect-social/connect-social';
import Section from '../section/section';
import React from 'react';
import PhoneIcon from '../../../images/svg-icons/phone-duotone.svg';

interface ISetUpACall {
  sectionsTitle: string;
  sectionDescription: string;
  tileConnectProps: IConnectTileProps;
}

export const SetUpACallSection = (props: ISetUpACall) => (
  <section className="call-section">
    <Section>
      <div className="call-section__wrapper">
        <div className="call-section__header">
          <span>
            <h2 className="call-section__title">{props.sectionsTitle}</h2>
            <p className="call-section__description">
              {props.sectionDescription}
            </p>
          </span>
          <ConnectTile {...props.tileConnectProps} />
        </div>
        <PhoneIcon className="call-section__icon" />
      </div>
    </Section>
  </section>
);
