import React from 'react';
import classNames from 'classnames';
import Title, { ITitleTextProps } from '../text/title';
import Text, { ITextProps } from '../text/text';
import Card, { CardTypes } from '../card/card';
import { Filter } from '../../filter/filter';
import { Tag } from '../../tag/tag-component';
import Icon, { IIconProps } from '../icon/icon';

export enum ListItemTypes {
  TEXT = 'text',
  CARD = 'card',
  FILTER = 'filter',
  TAG = 'tag',
  ICON = 'icon',
  ICON_DESCRIPTION_BELOW = 'icon-description-below',
  ICON_DESCRIPTION_RIGHT = 'icon-description-right',
}

export interface IListItemContentProps {
  titleText?: ITitleTextProps;
  description?: ITextProps;
  icon?: IIconProps;
  link?: string;
}

export interface IListItemProps {
  listType: ListItemTypes;
  itemId: number | string;
  shouldDisplayKey?: boolean;
  content: IListItemContentProps;
  className?: string;
}

const getListIconItem = (listType: ListItemTypes, content: IListItemContentProps, className?: string) => {
  return (
    <div className={classNames(className, 'list-item--' + listType)}>
      {content.icon && <Icon {...content.icon} />}
      {content.titleText && <Title {...content.titleText} className="icon-title" />}
      {content.description && <Text {...content.description} className="icon-description" />}
    </div>
  );
};

const getText = (title?: ITitleTextProps): ITextProps => {
  return title ? title.text : { text: '' };
};

const ListItemMap = ({ className, content, listType }: IListItemProps): Record<ListItemTypes, JSX.Element> => ({
  [ListItemTypes.TEXT]: (
    <div className={className + '__group'}>
      {content.titleText && <Title {...content.titleText} className={classNames(className, 'list-item__title')} />}
      {content.description && (
        <Text {...content.description} className={classNames(className, 'list-item__description')} />
      )}
    </div>
  ),
  [ListItemTypes.CARD]: (
    <Card cardType={CardTypes.COLUMN} style={{ gap: '32px' }} className={className}>
      {content.icon && <Icon {...content.icon} />}
      {content.titleText && <Title {...content.titleText} className={classNames(className, 'list-item__card-title')} />}
      {content.description && (
        <Text {...content.description} className={classNames(className, 'list-item__card-text')} />
      )}
    </Card>
  ),
  [ListItemTypes.FILTER]: <Filter className={classNames(className)} text={getText(content.titleText)} />,
  [ListItemTypes.TAG]: <Tag className={classNames(className)} text={getText(content.titleText)} />,
  [ListItemTypes.ICON]: <>{content.icon && <Icon {...content.icon} />}</>,
  [ListItemTypes.ICON_DESCRIPTION_BELOW]: getListIconItem(listType, content, className),
  [ListItemTypes.ICON_DESCRIPTION_RIGHT]: getListIconItem(listType, content, className),
});

const getListClassName = (props: IListItemProps): string => {
  return classNames('list-item', props.shouldDisplayKey && 'list-item--ordered', props.className);
};

const renderItem = (props: IListItemProps): JSX.Element => {
  if (props.content.link) {
    return <a href={props.content.link}>{ListItemMap(props)[props.listType]}</a>;
  }

  return ListItemMap(props)[props.listType];
};

export default function ListItem(props: IListItemProps) {
  return (
    <li className={getListClassName(props)}>
      {props.shouldDisplayKey && <span className="list-item__key">{props.itemId}</span>}
      {renderItem(props)}
    </li>
  );
}
