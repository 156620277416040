import React from 'react';
import { IStoryblokLink } from '../types';
import { Footer } from '../../containers/footer/footer';
import { IconscoutIcons } from '../../components/Atoms/icon/iconscout-icon';
import { getLinkUrl } from '../helpers/get-link-url';

interface IFooterStoryblokComponentBlok {
  columns: {
    title: string;
    links: {
      text: string;
      href: IStoryblokLink;
    }[];
  }[];
  solicalLinks: {
    href: IStoryblokLink;
    icon: IconscoutIcons;
  }[];
  additionalLinks: {
    text: string;
    href: IStoryblokLink;
  }[];
  copyrightNotice: string;
}
export function FooterStoryblokComponent({ blok }: { blok: IFooterStoryblokComponentBlok }) {
  const columns = blok.columns.map((c) => ({
    ...c,
    links: c.links.map((l) => ({ ...l, href: getLinkUrl(l.href) })),
  }));

  const socialLinks = blok.solicalLinks.map((s) => ({
    ...s,
    href: getLinkUrl(s.href),
  }));

  const additionalLinks = blok.additionalLinks.map((a) => ({
    ...a,
    href: getLinkUrl(a.href),
  }));

  return (
    <Footer
      columns={columns}
      socialLinks={socialLinks}
      additionalLinks={additionalLinks}
      copyrightNotice={blok.copyrightNotice}
    />
  );
}
