import Text, { ITextProps } from './text';
import React from 'react';
import classNames from 'classnames';
import { DuotoneIconSizes, DuotoneIcons } from '../icon/duotone-icon';
import DuotoneIconHoverable from '../icon/duotone-icon-hoverable';

export enum TitleTypes {
  HEADER_1 = 1,
  HEADER_2,
  HEADER_3,
  HEADER_4,
  HEADER_5,
  HEADER_6,
}

export interface ITitleTextProps {
  text?: ITextProps;
  titleType: TitleTypes;
  className?: string;
  icon?: DuotoneIcons;
}

function HeadlineMapper({ text, className, icon }: ITitleTextProps): Record<TitleTypes, JSX.Element> {
  const Content = () => (
    <>
      {icon && <DuotoneIconHoverable icon={icon} iconSize={DuotoneIconSizes.BIG} />}
      {text && <Text {...text} />}
    </>
  );

  return {
    [TitleTypes.HEADER_1]: (
      <h1 className={classNames(className, 'title-h1')}>
        <Content />
      </h1>
    ),
    [TitleTypes.HEADER_2]: (
      <h2 className={classNames(className, 'title-h2')}>
        <Content />
      </h2>
    ),
    [TitleTypes.HEADER_3]: (
      <h3 className={classNames(className, 'title-h3')}>
        <Content />
      </h3>
    ),
    [TitleTypes.HEADER_4]: (
      <h4 className={classNames(className, 'title-h4')}>
        <Content />
      </h4>
    ),
    [TitleTypes.HEADER_5]: (
      <h5 className={classNames(className, 'title-h5')}>
        <Content />
      </h5>
    ),
    [TitleTypes.HEADER_6]: (
      <h6 className={classNames(className, 'title-h6')}>
        <Content />
      </h6>
    ),
  };
}

export default function Title(props: ITitleTextProps) {
  return HeadlineMapper(props)[props.titleType];
}
