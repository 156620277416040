import React, { useState } from 'react';
import classNames from 'classnames';
import IconscoutIcon, { IconscoutIcons } from '../Atoms/icon/iconscout-icon';
import Text, { TextTypographies, TextWeights } from '../Atoms/text/text';
import { Link } from 'gatsby';
import { isInternalLink } from '../../helpers/is-link-external';

export interface IButtonProps {
  textContent: string;
  link?: string;
  isDisabled?: boolean;
  isPrimary?: boolean;
  isExtra?: boolean;
  onClick?: (...args: any[]) => void;
}

const defaultArrowColor = '#919399';

const getContent = (content?: string): string => content ?? 'No content';

const defineClassName = ({ isPrimary, isExtra }: IButtonProps): string => {
  if (isExtra) return 'button-extra';

  return isPrimary ? 'button-primary' : 'button-secondary';
};

const renderArrow = ({ isExtra }: IButtonProps): JSX.Element => {
  if (!isExtra) return <></>;

  return (
    <div className="arrow__wrapper">
      <IconscoutIcon icon={IconscoutIcons.ARROW_UP_RIGHT} className="arrow__icon-backward" color={defaultArrowColor} />
      <IconscoutIcon icon={IconscoutIcons.ARROW_UP_RIGHT} className="arrow__icon-forward" color={defaultArrowColor} />
    </div>
  );
};

export const Button = (props: IButtonProps): JSX.Element => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const ButtonContent = () => (
    <>
      <Text
        text={getContent(props.textContent)}
        typography={TextTypographies.CONTROL_BUTTON}
        weight={TextWeights.BOLD}
      />
      {renderArrow(props)}
    </>
  );

  const buttonProps = {
    className: classNames('button', defineClassName(props)),
    onClick: handleClick,
  };

  if (!props.link) {
    return (
      <button {...buttonProps} disabled={!!props.isDisabled}>
        <ButtonContent />
      </button>
    );
  }

  if (isInternalLink(props.link)) {
    return (
      <Link {...buttonProps} to={`/${props.link}`}>
        <ButtonContent />
      </Link>
    );
  } else {
    return (
      <a {...buttonProps} href={props.link} target="_blank" rel="noreferrer">
        <ButtonContent />
      </a>
    );
  }
};
