import React from 'react';
import LeftLeafSvgIcon from '../../../images/svg-icons/left-leaf-duotone.svg';
import RightLeafSvgIcon from '../../../images/svg-icons/right-leaf-duotone.svg';

export interface IBenefitsSectionAchievement {
  title: string;
  subtitle: string;
}

interface IBenefitsSectionAchievementsProps {
  achievements: IBenefitsSectionAchievement[];
}
export default function BenefitsSectionAchievements({
  achievements,
}: IBenefitsSectionAchievementsProps) {
  return (
    <div className="benefits-section__achievements">
      {achievements.map((achievement, idx) => (
        <div key={idx} className="benefits-section__achievements__item">
          <div className="benefits-section__achievements__item__title">
            <LeftLeafSvgIcon />
            <h3>{achievement.title}</h3>
            <RightLeafSvgIcon />
          </div>
          <div className="benefits-section__achievements__item__subtitle">
            {achievement.subtitle}
          </div>
        </div>
      ))}
    </div>
  );
}
