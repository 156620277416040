import React from 'react';
import OrderedListSection, {
  OrderedListSectionTypesEnum,
} from '../../containers/sections/ordered-list-section/ordered-list-section';
import { OrderedListIconsEnum } from '../../containers/sections/ordered-list-section/ordered-list-section-header';

interface IOrderedListSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  type: OrderedListSectionTypesEnum;
  listTitle: string;
  listDescription: string;
  headerIcon: OrderedListIconsEnum;
  list: {
    title: string;
    description: string;
  }[];
  showAll: boolean;
}

export function OrderedListSectionStoryblokComponent({ blok }: { blok: IOrderedListSectionStoryblokComponentBlok }) {
  return (
    <OrderedListSection
      title={blok.sectionTitle}
      description={blok.sectionDescription}
      listTitle={blok.listTitle}
      listDescription={blok.listDescription}
      list={blok.list}
      showAll={blok.showAll}
      headerIcon={blok.headerIcon || undefined}
      type={blok.type}
    />
  );
}
