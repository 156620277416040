import React, { Suspense } from 'react';

const AngularLeafsSvgIcon = React.lazy(() => import('../../../images/svg-icons/angular-leafs.svg'));
const RocketLeafsSvgIcon = React.lazy(() => import('../../../images/svg-icons/Rocke-leafs.svg'));
const BriefcaseLeafsSvgIcon = React.lazy(() => import('../../../images/svg-icons/brief-case-leafs.svg'));

export enum OrderedListIconsEnum {
  angular = 'angular',
  briefcase = 'briefcase',
  rocket = 'rocket',
}

const ExplanationSectionIconsMap: Record<OrderedListIconsEnum, any> = {
  [OrderedListIconsEnum.angular]: AngularLeafsSvgIcon,
  [OrderedListIconsEnum.briefcase]: BriefcaseLeafsSvgIcon,
  [OrderedListIconsEnum.rocket]: RocketLeafsSvgIcon,
};

interface IOrderedListSectionHeaderProps {
  title: string;
  description?: string;
  icon?: OrderedListIconsEnum;
}

export default function OrderedListSectionHeader({ title, description, icon }: IOrderedListSectionHeaderProps) {
  return (
    <div className="ordered-list-section__header">
      {icon && <Suspense>{React.createElement(ExplanationSectionIconsMap[icon])}</Suspense>}
      <h2>{title}</h2>
      {description && <p>{description}</p>}
    </div>
  );
}
