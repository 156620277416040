import React from 'react';
import Section from '../section/section';
import LogoSvgIcon from '../../../images/svg-icons/protoq-logo-background.svg';
import CaseCard, { ICaseCardProps } from '../../../components/case-card/case-card';
import Slider from '../../../components/slider/slider';

interface IRelatedCasesProps {
  sectionTitle: string;
  sectionDescription?: string;
  relatedCasesItem: ICaseCardProps[];
}

const RelatedCasesStudyComponent = (props: IRelatedCasesProps) => (
  <section className="related-cases-section">
    <Section title={props.sectionTitle}>
      <Slider
        slides={props.relatedCasesItem.map((item: ICaseCardProps, key: number) => (
          <CaseCard key={key} {...item} />
        ))}
        options={{
          breakpoints: {
            '(min-width: 320px)': {
              slides: { perView: 1 },
            },
            '(min-width: 540px)': {
              slides: { perView: 1.5 },
            },
            '(min-width: 690px)': {
              slides: { perView: 2.5 },
            },
            '(min-width: 1154px)': {
              slides: { perView: 3 },
            },
          },
        }}
      />
      <LogoSvgIcon className="bg-logo" />
    </Section>
  </section>
);

export default RelatedCasesStudyComponent;
