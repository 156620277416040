import React from 'react';
import { SpecializationsSection } from '../../containers/sections/specializations-section/specializations-section';
import { IStoryblokLink } from '../types';

interface ISpecializationsSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  gradientBackground: boolean;
  items: {
    title: string;
    text: string;
    link: IStoryblokLink;
  }[];
}
export function SpecializationsSectionStoryblokComponent({
  blok,
}: {
  blok: ISpecializationsSectionStoryblokComponentBlok;
}) {
  return (
    <SpecializationsSection
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      items={blok.items.map((i) => ({ text: i.text, title: i.title, link: i.link.url || i.link.cached_url }))}
      hasGradientBackground={blok.gradientBackground}
    />
  );
}
