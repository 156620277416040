import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import LogoSvgIcon from '../../images/svg-icons/logo.svg';
import { Button } from '../../components/button/button';
import { PagesEnum } from '../../types/Pages.enum';
import HeaderMenu, { IHeaderNavigationMenu } from './header-menu/header-menu';
import { useEventListener } from '../../hooks/use-event-listener';
import BurgerButton from './burger-button';

export interface IHeaderNavigation {
  title: string;
  menues?: IHeaderNavigationMenu[];
  onClick?: () => void;
}

interface IHeaderProps {
  headerNavigation: IHeaderNavigation[];
  headerNavigationMobile: IHeaderNavigationMenu[];
}
export default function Header({ headerNavigation, headerNavigationMobile }: IHeaderProps) {
  const [activeMenuIdx, setActiveMenuIdx] = useState<number | null>(null);
  const [activeMenu, setActiveMenu] = useState<IHeaderNavigationMenu[] | null>(null);
  const [isBurgerButtonActive, setBurgerButtonActive] = useState<boolean>(false);

  useEffect(() => {
    if (activeMenuIdx !== null) {
      setActiveMenu(headerNavigation?.[activeMenuIdx].menues || null);
    } else {
      setActiveMenu(null);
    }
  }, [activeMenuIdx]);

  const closeHeaderMenu = () => setActiveMenuIdx(null);

  const toggleMobileMenu = () => {
    setBurgerButtonActive((prev) => !prev);
    setActiveMenu(isBurgerButtonActive ? null : headerNavigationMobile);
  };

  const closeMobileMenu = () => {
    setBurgerButtonActive(false);
    setActiveMenu(null);
  };

  useEventListener('click', (event) => {
    if (!event.target.closest('.header')) {
      closeHeaderMenu();
    }
  });

  return (
    <>
      <header className="header">
        <div className="header__wrapper">
          <Link className="header__logo" to="/" aria-label="Go to ProtoQ home page">
            <LogoSvgIcon />
          </Link>

          <nav className="header__nav-list">
            <ul>
              {headerNavigation?.map((i, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    setActiveMenuIdx(headerNavigation?.[idx].menues ? idx : null);
                    i.onClick?.();
                  }}
                  className={classNames(activeMenuIdx === idx && 'active')}>
                  {i.title}
                </li>
              ))}
            </ul>
          </nav>

          <Button textContent="Get A Quote" link={PagesEnum.GET_A_QUOTE} />

          <BurgerButton isActive={isBurgerButtonActive} onClick={toggleMobileMenu} />
        </div>
      </header>

      <HeaderMenu isActive={!!activeMenu} closeMenu={closeMobileMenu} menues={activeMenu || undefined} />
    </>
  );
}
