import React from 'react';
import ProtoqDuotoneSvg from '../../../images/svg-icons/primary-section/protoq-logo-duotone.svg';
import ProtoqDuotoneDimSvg from '../../../images/svg-icons/primary-section/protoq-logo-duotone-dim.svg';

import PuzzleDuotoneSvg from '../../../images/svg-icons/primary-section/puzzle-duotone.svg';
import PuzzleDuotoneDimSvg from '../../../images/svg-icons/primary-section/puzzle-duotone-dim.svg';

import BoltDuotoneSvg from '../../../images/svg-icons/primary-section/bolt-duotone.svg';
import BoltDuotoneDimSvg from '../../../images/svg-icons/primary-section/bolt-duotone-dim.svg';

import HouseSignalDuotoneSvg from '../../../images/svg-icons/primary-section/house-signal-duotone.svg';
import HouseSignalDuotoneDimSvg from '../../../images/svg-icons/primary-section/house-signal-duotone-dim.svg';

import LaptopDuotoneSvg from '../../../images/svg-icons/primary-section/laptop-code-duotone.svg';
import LaptopDuotoneDimSvg from '../../../images/svg-icons/primary-section/laptop-code-duotone-dim.svg';

import MobileDuotoneSvg from '../../../images/svg-icons/primary-section/mobile-duotone.svg';
import MobileDuotoneDimSvg from '../../../images/svg-icons/primary-section/mobile-duotone-dim.svg';

import TrowelBricksDuotoneSvg from '../../../images/svg-icons/primary-section/trowel-bricks-duotone.svg';
import TrowelBricksDuotoneDimSvg from '../../../images/svg-icons/primary-section/trowel-bricks-duotone-dim.svg';

import BriefcaseDuotoneSvg from '../../../images/svg-icons/primary-section/briefcase-duotone.svg';
import BriefcaseDuotoneDimSvg from '../../../images/svg-icons/primary-section/briefcase-duotone-dim.svg';

import AngularDuotoneSvg from '../../../images/svg-icons/primary-section/angular-duotone.svg';
import AngularDuotoneDimSvg from '../../../images/svg-icons/primary-section/angular-duotone-dim.svg';

import NodeJSDuotoneSvg from '../../../images/svg-icons/primary-section/nodejs-duotone.svg';
import NodeJSDuotoneDimSvg from '../../../images/svg-icons/primary-section/nodejs-duotone-dim.svg';

import RocketDuotoneSvg from '../../../images/svg-icons/primary-section/rocket-duotone.svg';
import RocketDuotoneDimSvg from '../../../images/svg-icons/primary-section/rocket-duotone-dim.svg';

import { useBreakpoint } from '../../../hooks/use-breakpoint';

export enum PrimarySectionIcons {
  PROTOQ = 'protoq',
  BOLT = 'bolt',
  HOUSE_SIGNAL = 'house-signal',
  LAPTOP = 'laptop',
  MOBILE = 'mobile',
  PUZZLE = 'puzzle',
  TROWEL_BRICKS = 'trowel-bricks',
  BRIEFCASE = 'briefcase',
  ANGULAR = 'angular',
  NODEJS = 'nodejs',
  ROCKET = 'rocket',
}

export const PrimarySectionIconsMap: Record<PrimarySectionIcons, JSX.Element> = {
  [PrimarySectionIcons.PROTOQ]: <ProtoqDuotoneSvg />,
  [PrimarySectionIcons.PUZZLE]: <PuzzleDuotoneSvg />,
  [PrimarySectionIcons.BOLT]: <BoltDuotoneSvg />,
  [PrimarySectionIcons.HOUSE_SIGNAL]: <HouseSignalDuotoneSvg />,
  [PrimarySectionIcons.LAPTOP]: <LaptopDuotoneSvg />,
  [PrimarySectionIcons.MOBILE]: <MobileDuotoneSvg />,
  [PrimarySectionIcons.TROWEL_BRICKS]: <TrowelBricksDuotoneSvg />,
  [PrimarySectionIcons.BRIEFCASE]: <BriefcaseDuotoneSvg />,
  [PrimarySectionIcons.ANGULAR]: <AngularDuotoneSvg />,
  [PrimarySectionIcons.NODEJS]: <NodeJSDuotoneSvg />,
  [PrimarySectionIcons.ROCKET]: <RocketDuotoneSvg />,
};

export const PrimarySectionDimIconsMap: Record<PrimarySectionIcons, JSX.Element> = {
  [PrimarySectionIcons.PROTOQ]: <ProtoqDuotoneDimSvg />,
  [PrimarySectionIcons.PUZZLE]: <PuzzleDuotoneDimSvg />,
  [PrimarySectionIcons.BOLT]: <BoltDuotoneDimSvg />,
  [PrimarySectionIcons.HOUSE_SIGNAL]: <HouseSignalDuotoneDimSvg />,
  [PrimarySectionIcons.LAPTOP]: <LaptopDuotoneDimSvg />,
  [PrimarySectionIcons.MOBILE]: <MobileDuotoneDimSvg />,
  [PrimarySectionIcons.TROWEL_BRICKS]: <TrowelBricksDuotoneDimSvg />,
  [PrimarySectionIcons.BRIEFCASE]: <BriefcaseDuotoneDimSvg />,
  [PrimarySectionIcons.ANGULAR]: <AngularDuotoneDimSvg />,
  [PrimarySectionIcons.NODEJS]: <NodeJSDuotoneDimSvg />,
  [PrimarySectionIcons.ROCKET]: <RocketDuotoneDimSvg />,
};

interface IPrimarySectionIconProps {
  icon: PrimarySectionIcons;
}
export default function PrimarySectionIcon({ icon }: IPrimarySectionIconProps) {
  const TOGGLE_VIEW_BREAKPOINT = 926;
  const isBreakpoint = useBreakpoint(TOGGLE_VIEW_BREAKPOINT);

  return (
    <span className="logo">
      {isBreakpoint === true && PrimarySectionIconsMap[icon]}
      {isBreakpoint === false && PrimarySectionDimIconsMap[icon]}
    </span>
  );
}
