import React, { PropsWithChildren } from 'react';
import Section from '../section/section';
import { SectionTitle } from '../section-typography/section-typography';
import { DuotoneIcons } from '../../../components/Atoms/icon/duotone-icon';
import Icon from '../../../components/Atoms/icon/icon';

interface IExplanationSectionProps {
  sectionTitle: string;
  sectionIconName: DuotoneIcons;
  paragraphs: {
    title: string;
    description: string;
  }[];
}

export default function ExplanationSection(props: IExplanationSectionProps) {
  return (
    <section className="explanation-section">
      <Section>
        <div className="explanation-section__wrapper">
          <div className="explanation-section__header">
            <SectionTitle title={props.sectionTitle} />
            <span className="explanation-section__icon">
              <Icon icon={props.sectionIconName} />
            </span>
          </div>
          <div className="explanation-section__paragraphs">
            {props.paragraphs.map((item, key: number) => (
              <div key={key} className="explanation-section__paragraph">
                {item.title && <h3 className="explanation-section__paragraph-title">{item.title}</h3>}
                {item.description && <p className="explanation-section__paragraph-description">{item.description}</p>}
              </div>
            ))}
          </div>
        </div>
      </Section>
    </section>
  );
}

export function ExplanationSectionsArray({ children }: PropsWithChildren) {
  return <div className="explanation-section-array">{children}</div>;
}
