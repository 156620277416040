import React from 'react';
import Section from '../section/section';
import EmphasizedWordsAnimated from '../../../components/emphasized-words-animated/emphasized-words-animated';
import PrimarySectionIcon, { PrimarySectionIcons } from './primary-section-icon-mapper';

const ANIMATED_WORDS_MARKER = '#$animated-words';

const parseTitle = (title: string, words: string[][]) => {
  if (title.indexOf(ANIMATED_WORDS_MARKER) < 0) {
    return <h1>{title}</h1>;
  }

  const parts = title.split(ANIMATED_WORDS_MARKER);

  return (
    <h1>
      {parts.map((part, idx) => {
        const partWords = words.shift();
        return (
          <React.Fragment key={idx}>
            {part}
            {partWords && <EmphasizedWordsAnimated words={partWords} />}
          </React.Fragment>
        );
      })}
    </h1>
  );
};

export interface IPrimarySectionProps {
  // Use "[word1, word2, word3, ...]" statement to display typing animation changing wrods "word1", "word2", "word3"
  title: string;
  subtitle: string;
  icon: PrimarySectionIcons;
  footerComponent?: JSX.Element;
}

export default function PrimarySection({ title, subtitle, icon, footerComponent }: IPrimarySectionProps) {
  const emphasizedWords: string[][] = title.match(/\[(.*?)\]/g)?.map((v) => v.slice(1, -1).split(', ')) || [];
  const titleWithMarker = title.replace(/\[(.*?)\]/g, ANIMATED_WORDS_MARKER);

  return (
    <section className="primary-section">
      <Section>
        <div className="primary-section__content">
          {parseTitle(titleWithMarker, emphasizedWords)}
          <p>{subtitle}</p>
        </div>
        <PrimarySectionIcon icon={icon} />
        {footerComponent}
      </Section>
    </section>
  );
}
