import React, { useEffect, useRef } from 'react';
import SliderSectionSliderThumbs from './slider-section-slider-thumbs';

const ACTIVE_SLIDE_CLASS = 'active-slide';

interface ISliderSectionSliderProps {
  activeSlide: number;
  images: {
    src: string;
    alt: string;
  }[];
  onChange?: (sliderIdx: number) => void;
}
export default function SliderSectionSlider({ activeSlide, images, onChange }: ISliderSectionSliderProps) {
  const slidersCount = images.length;
  const sliderRef = useRef<any>(null);

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }
  }, [sliderRef]);

  useEffect(() => {
    updateSlidesState();
    onChange?.(activeSlide);
  }, [activeSlide]);

  const updateSlidesState = () => {
    Array.from<HTMLElement>(sliderRef.current.children).map((slide, idx) => {
      if (idx > activeSlide) {
        slide.classList.remove(ACTIVE_SLIDE_CLASS);
      }

      if (idx <= activeSlide) {
        if (!slide.classList.contains(ACTIVE_SLIDE_CLASS)) {
          slide.classList.add(ACTIVE_SLIDE_CLASS);
        }
      }
    });
  };

  return (
    <div className="slider-section__slider">
      <div className="slider-section__slider__images-box">
        <div ref={sliderRef} className="slider-section__slider__images-box__wrapper">
          {images.map((img, idx) => (
            <div key={idx} style={{ zIndex: idx + 1 }}>
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
      </div>
      <SliderSectionSliderThumbs count={slidersCount} activeThumb={activeSlide + 1} />
    </div>
  );
}
