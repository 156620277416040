import { useEffect, useState } from 'react';
import { useEventListener } from './use-event-listener';
import { isBrowser } from '../helpers/is-browser';

export enum BreakpointsEnum {
  Mobile = 320,
  Tablet = 768,
  Desktop = 1280,
}

export enum ScreenSizesEnum {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export enum ScreenSizeCheckType {
  TargetInnerWidth = 'target-inner-width',
  TargetOuterWidth = 'target-outer-width',
  WindowInnerWidth = 'window-inner-width',
  WindowOuterWidth = 'window-outer-width',
}

export function useBreakpoint(screenSize: ScreenSizesEnum | number, checkType = ScreenSizeCheckType.TargetOuterWidth) {
  const checkBreakpoint = (currentScreenWidth: number) => {
    if (screenSize === ScreenSizesEnum.Desktop) {
      return currentScreenWidth >= BreakpointsEnum.Desktop;
    }

    if (screenSize === ScreenSizesEnum.Tablet) {
      return currentScreenWidth >= BreakpointsEnum.Tablet && currentScreenWidth < BreakpointsEnum.Desktop;
    }

    if (screenSize === ScreenSizesEnum.Mobile) {
      return currentScreenWidth < BreakpointsEnum.Tablet;
    }

    return currentScreenWidth >= screenSize;
  };

  const [isBreakpoint, setIsBreakpoint] = useState<boolean | null>();

  useEffect(() => {
    setIsBreakpoint(isBrowser() ? checkBreakpoint(window.innerWidth) : null);
  }, []);

  const getCurrentScreenWidth = (target: { outerWidth: number; innerWidth: number }) => {
    switch (checkType) {
      case ScreenSizeCheckType.TargetInnerWidth:
        return target.innerWidth;

      case ScreenSizeCheckType.TargetOuterWidth:
        return target.outerWidth;

      case ScreenSizeCheckType.WindowInnerWidth:
        return isBrowser() ? window.innerWidth : target.innerWidth;

      case ScreenSizeCheckType.WindowOuterWidth:
        return isBrowser() ? window.outerWidth : target.innerWidth;
    }
  };

  useEventListener('resize', (event: any) => {
    const currentScreenWidth = getCurrentScreenWidth(event.target);
    setIsBreakpoint(checkBreakpoint(currentScreenWidth));
  });

  return isBreakpoint;
}
