import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import HeaderMenuItem, { IHeaderMenuItemProps } from './header-menu-item';

export interface IHeaderNavigationMenu {
  title?: string;
  items: IHeaderMenuItemProps[];
}

interface IHeaderMenuProps {
  menues?: IHeaderNavigationMenu[];
  isActive: boolean;
  closeMenu: () => void;
}
export default function HeaderMenu(props: IHeaderMenuProps) {
  const [menues, setMenues] = useState(props.menues);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.menues) {
      setMenues(props.menues);
    }
  }, [props.menues]);

  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);

  return (
    <nav className={classNames('header-menu', !isActive && 'header-menu--closed')}>
      {menues?.map((col, idx) => (
        <div key={idx} className="header-menu__column">
          {col.title && menues.length > 1 && <h6 className="header-menu__column__title">{col.title}</h6>}

          <ul className="header-menu__column__list">
            {col.items.map((i, idx) => (
              <li key={idx} className="header-menu__column__list__item">
                <HeaderMenuItem key={idx} closeMenu={props.closeMenu} {...i} />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
}
