import React from 'react';

export default function SvgPurpleGradient(): JSX.Element {
  return (
    <svg display="block" width="0" height="0" viewBox="0 0 0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id="svg-purple-gradient"
          x1="0.658203"
          y1="13.9789"
          x2="27.3249"
          y2="13.9789"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A957E2" />
          <stop offset="1" stopColor="#3794D4" />
        </linearGradient>
      </defs>
    </svg>
  );
}
