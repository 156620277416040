import React from 'react';
import IoTMobileAppDevelopmentSection from '../../containers/sections/iot-mobile-development-section/iot-mobile-development-section';
import { IServiceTileStorybookComponentBlok } from './service-tile';
import { IStoryblokImageAsset } from '../types';

interface IIoTMobileDevelopmentSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  cardsTitle: string;
  cards: IServiceTileStorybookComponentBlok[];
  image: IStoryblokImageAsset;
}
export function IoTMobileDevelopmentSectionStoryblokComponent({
  blok,
}: {
  blok: IIoTMobileDevelopmentSectionStoryblokComponentBlok;
}) {
  return (
    <IoTMobileAppDevelopmentSection
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      cardsTitle={blok.cardsTitle}
      cards={blok.cards.map((i) => ({ ...i, link: i.link.url }))}
      image={{
        src: blok.image.filename,
        alt: blok.image.alt || '',
      }}
    />
  );
}
