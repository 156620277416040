import { StoryblokComponent } from 'gatsby-source-storyblok';
import React from 'react';

export function GradientBackgroundBlokStoryblokComponent({ blok }: { blok: any }) {
  return (
    <div style={{ background: 'linear-gradient(90deg, rgba(169, 87, 226, 0.08) 0%, rgba(55, 148, 212, 0.08) 100%)' }}>
      {blok?.block?.map((blok: any) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </div>
  );
}
