import React, { useState } from 'react';
import SliderSectionInfoBlockWrapper from '../slider-section-inform-block/slider-section-info-block-wrapper';
import SliderSectionSlider from '../slider-section-slider/slider-section-slider';
import { ISliderSectionSlider } from '../slider-section';
import ProtoQLogoSvg from '../../../../images/svg-icons/protoq_logo_transparent_gradient.svg';

interface ISliderSectionDesktopLayoutProps {
  sectionTitle?: string;
  slides: ISliderSectionSlider[];
  onSlideChange?: (slideIdx: number) => void;
}
export default function SliderSectionDesktopLayout({
  slides,
  sectionTitle,
  onSlideChange,
}: ISliderSectionDesktopLayoutProps) {
  const [isSvgIconIncresed, setIsSvgIconIncresed] = useState(false);

  const [activeInfoBlock, setActiveInfoBlock] = useState(0);

  const animateSvgLogoPulse = () => {
    setIsSvgIconIncresed(true);
    setTimeout(() => setIsSvgIconIncresed(false), 300);
  };

  const onSlideChangeHandler = (slideIdx: number) => {
    animateSvgLogoPulse();
    onSlideChange?.(slideIdx);
  };

  const onActiveInfoblockChange = (infoBlockIdx: number) => {
    setActiveInfoBlock(infoBlockIdx);
  };

  return (
    <div>
      <div className="slider-section__wrapper">
        <SliderSectionInfoBlockWrapper
          changeActiveInfoBlock={onActiveInfoblockChange}
          infoBlocks={slides.map((i) => i.info)}
          sectionTitle={sectionTitle}
        />

        <div className="slider-section__slider-wrapper">
          <SliderSectionSlider
            images={slides.map((i) => i.image)}
            activeSlide={activeInfoBlock}
            onChange={onSlideChangeHandler}
          />
          <ProtoQLogoSvg className={isSvgIconIncresed ? 'slider-section__svg-logo--increased' : ''} />
        </div>
      </div>
    </div>
  );
}
