import React from 'react';
import Icon from '../../../../components/Atoms/icon/icon';
import { DuotoneIconSizes, DuotoneIcons } from '../../../../components/Atoms/icon/duotone-icon';

export interface IPrimarySectionFooterProjectInfoTile {
  icon: DuotoneIcons;
  title: string;
  text: string;
}

export interface IRelatedCasesPrimarySectionFooterActionProps {
  items: IPrimarySectionFooterProjectInfoTile[];
}

export default function RelatedCasesPrimarySectionFooterAction(props: IRelatedCasesPrimarySectionFooterActionProps) {
  return (
    <div className="primary-section__footer primary-section__related-cases-footer-action">
      <div className="primary-section__related-cases-footer-action__items">
        {props.items.map((item, idx) => (
          <div key={idx} className="primary-section__related-cases-footer-action__items__item">
            <div className="primary-section__related-cases-footer-action__items__item__icon">
              <Icon icon={item.icon} iconSize={DuotoneIconSizes.MEDIUM} />
            </div>
            <span className="primary-section__related-cases-footer-action__items__item-column">
              <div className="primary-section__related-cases-footer-action__items__item__title">{item.title}</div>
              <div className="primary-section__related-cases-footer-action__items__item__text">{item.text}</div>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
