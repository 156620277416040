import classNames from 'classnames';
import React from 'react';

interface IBurgerButtonProps {
  isActive: boolean;
  onClick: () => void;
}
export default function BurgerButton({ isActive, onClick }: IBurgerButtonProps) {
  return (
    <div className={classNames('burger-button', isActive && 'burger-button--active')} onClick={onClick}>
      <span />
      <span />
    </div>
  );
}
