const isPreviewStoryblok = process.env.GATSBY_STORYBLOK_PREVIEW_ENABLE
  ? process.env.GATSBY_STORYBLOK_PREVIEW_ENABLE.trim() === 'true'
  : false;
const storyblokApiAccessToken = process.env.GATSBY_STORYBLOK_API_ACCESS_TOKEN;
const storyblokPreviewApiAccessToken = process.env.GATSBY_STORYBLOK_PREVIEW_API_ACCESS_TOKEN;
const storyblokPageComponentType = process.env.GATSBY_STORYBLOK_PAGE_COMPONENT_TYPE || 'page';

export const environment = {
  production: !isPreviewStoryblok,
  storyblokApi: 'https://api.storyblok.com/v2/cdn/',
  storyblokApiAccessToken: isPreviewStoryblok ? storyblokPreviewApiAccessToken : storyblokApiAccessToken,
  storyblokVersion: isPreviewStoryblok ? 'draft' : 'published',
  siteUrl: process.env.GATSBY_SITE_URL || 'https://dev.protoq.dev/',
  getAllStoryblokPagesQueryParams: {
    'filter_query[component][like]': storyblokPageComponentType,
  },
  globalSettingsStoryPath: 'global-settings',
  gtmOptions: process.env.GTM_ID
    ? {
        id: process.env.GTM_ID,
        dataLayerName: process.env.GTM_DATA_LAYER_NAME || undefined,
        gtmAuth: process.env.GTM_AUTH || undefined,
        gtmPreview: process.env.GTM_PREVIEW || undefined,
        enableWebVitalsTracking: true,
      }
    : undefined,
};
