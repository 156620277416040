import React, { Suspense } from 'react';

const PHPSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/php.svg'));
const LaravelSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/laravel.svg'));
const PythonSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/python.svg'));
const JavaSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/java.svg'));
const DjangoSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/django-short.svg'));
const SymfonySvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/symfony.svg'));
const NodeJSSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/nodejs.svg'));
const ElasticsearchSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/elasticsearch.svg'));
const KubernetesSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/kubernetes.svg'));
const Html5SvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/html5.svg'));
const ReactJSSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/reactjs.svg'));
const JQuerySvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/jquery.svg'));
const VueJSSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/vuejs.svg'));
const ReduxSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/redux.svg'));
const CSS3SvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/css3.svg'));
const JavascriptSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/javascript.svg'));
const AngularSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/angular.svg'));
const GatsbySvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/gatsby.svg'));
const PWASvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/pwa.svg'));
const SwiftSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/swift.svg'));
const IOSSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/ios.svg'));
const IonicSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/ionic.svg'));
const FluttterSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/flutter.svg'));
const KotlinSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/kotlin.svg'));
const AndroidSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/android.svg'));
const UnitySvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/unity.svg'));
const GraphQLSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/graphql.svg'));
const SQLSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/sql.svg'));
const SQLiteSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/sqlite.svg'));
const MongoSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/mongo.svg'));
const PostgreSQLSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/postgresql.svg'));
const RedisSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/redis.svg'));
const FigmaSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/figma.svg'));
const PhotoshopSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/edobe-photoshop.svg'));
const XdSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/edobe-xd.svg'));
const SketchSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/sketch.svg'));
const InVisionSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/invision-color.svg'));
const MarvelSvgIcon = React.lazy(() => import('../../../images/svg-icons/technologies/marvel.svg'));

export enum TechnologiesIcons {
  PHP = 'php',
  LARAVEL = 'laravel',
  PYTHON = 'python',
  JAVA = 'java',
  DJANGO = 'django',
  SYMFONY = 'symfony',
  NODEJS = 'nodejs',
  ELASTICSEARCH = 'elasticsearch',
  KUBERNETES = 'kubernetes',
  HTML5 = 'html5',
  REACTJS = 'react',
  JQUERY = 'jquery',
  VUEJS = 'vue',
  REDUX = 'redux',
  CSS3 = 'css3',
  JAVASCRIPT = 'javascript',
  ANGULAR = 'angular',
  GATSBY = 'gatsby',
  PWA = 'pwa',
  SWIFT = 'swift',
  IOS = 'ios',
  IONIC = 'ionic',
  FLUTTTER = 'flutter',
  KOTLIN = 'kotlin',
  ANDROID = 'android',
  UNITY = 'unity',
  GRAPHQL = 'graphql',
  SQL = 'sql',
  SQLITE = 'sqlite',
  MONGO = 'mongo',
  POSTGRESQL = 'postgresql',
  REDIS = 'redis',
  FIGMA = 'figma',
  PHOTOSHOP = 'photoshop',
  XD = 'xd',
  SKETCH = 'sketch',
  INVISION = 'invision',
  MARVEL = 'marvel',
}

const TechnologiesIconsMapper: Record<TechnologiesIcons, any> = {
  [TechnologiesIcons.PHP]: PHPSvgIcon,
  [TechnologiesIcons.LARAVEL]: LaravelSvgIcon,
  [TechnologiesIcons.PYTHON]: PythonSvgIcon,
  [TechnologiesIcons.JAVA]: JavaSvgIcon,
  [TechnologiesIcons.DJANGO]: DjangoSvgIcon,
  [TechnologiesIcons.SYMFONY]: SymfonySvgIcon,
  [TechnologiesIcons.NODEJS]: NodeJSSvgIcon,
  [TechnologiesIcons.ELASTICSEARCH]: ElasticsearchSvgIcon,
  [TechnologiesIcons.KUBERNETES]: KubernetesSvgIcon,
  [TechnologiesIcons.HTML5]: Html5SvgIcon,
  [TechnologiesIcons.REACTJS]: ReactJSSvgIcon,
  [TechnologiesIcons.JQUERY]: JQuerySvgIcon,
  [TechnologiesIcons.VUEJS]: VueJSSvgIcon,
  [TechnologiesIcons.REDUX]: ReduxSvgIcon,
  [TechnologiesIcons.CSS3]: CSS3SvgIcon,
  [TechnologiesIcons.JAVASCRIPT]: JavascriptSvgIcon,
  [TechnologiesIcons.ANGULAR]: AngularSvgIcon,
  [TechnologiesIcons.GATSBY]: GatsbySvgIcon,
  [TechnologiesIcons.PWA]: PWASvgIcon,
  [TechnologiesIcons.SWIFT]: SwiftSvgIcon,
  [TechnologiesIcons.IOS]: IOSSvgIcon,
  [TechnologiesIcons.IONIC]: IonicSvgIcon,
  [TechnologiesIcons.FLUTTTER]: FluttterSvgIcon,
  [TechnologiesIcons.KOTLIN]: KotlinSvgIcon,
  [TechnologiesIcons.ANDROID]: AndroidSvgIcon,
  [TechnologiesIcons.UNITY]: UnitySvgIcon,
  [TechnologiesIcons.GRAPHQL]: GraphQLSvgIcon,
  [TechnologiesIcons.SQL]: SQLSvgIcon,
  [TechnologiesIcons.SQLITE]: SQLiteSvgIcon,
  [TechnologiesIcons.MONGO]: MongoSvgIcon,
  [TechnologiesIcons.POSTGRESQL]: PostgreSQLSvgIcon,
  [TechnologiesIcons.REDIS]: RedisSvgIcon,
  [TechnologiesIcons.FIGMA]: FigmaSvgIcon,
  [TechnologiesIcons.PHOTOSHOP]: PhotoshopSvgIcon,
  [TechnologiesIcons.XD]: XdSvgIcon,
  [TechnologiesIcons.SKETCH]: SketchSvgIcon,
  [TechnologiesIcons.INVISION]: InVisionSvgIcon,
  [TechnologiesIcons.MARVEL]: MarvelSvgIcon,
};

export interface ITechnologiesIconProps {
  icon: TechnologiesIcons | string;
  alt: string;
}

const TechnologiesIcon = (props: ITechnologiesIconProps) => {
  // If "icon" value is in enum - get icon from enum
  if (Object.values<string>(TechnologiesIcons).includes(props.icon)) {
    return <Suspense>{React.createElement(TechnologiesIconsMapper[props.icon as TechnologiesIcons])}</Suspense>;
  }

  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  React.useEffect(() => {
    const image = new Image();
    image.onload = () => setIsImageLoaded(true);
    image.src = props.icon;

    return () => {
      image.onload = null;
    };
  }, []);

  if (!isImageLoaded) {
    return null;
  }

  // Otherwise - handle "icon" value as a url path
  return <img src={props.icon} alt={props.alt} />;
};

export default TechnologiesIcon;
