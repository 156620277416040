/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import ArrowUpRightIcon from '@iconscout/react-unicons/icons/uil-arrow-up-right';
import ArrowDownIcon from '@iconscout/react-unicons/icons/uil-arrow-down';
import ArrowRightIcon from '@iconscout/react-unicons/icons/uil-arrow-right';
import ArrowLeftIcon from '@iconscout/react-unicons/icons/uil-arrow-left';
import LayersIcon from '@iconscout/react-unicons/icons/uil-layers';
import ClockIcon from '@iconscout/react-unicons/icons/uil-clock';
import MapMarkerIcon from '@iconscout/react-unicons/icons/uil-map-marker';
import SkypeIcon from '@iconscout/react-unicons/icons/uil-skype';
import TelegramIcon from '@iconscout/react-unicons/icons/uil-telegram';
import WhatsappAltIcon from '@iconscout/react-unicons/icons/uil-whatsapp-alt';
import LinkedinIcon from '@iconscout/react-unicons/icons/uil-linkedin';
import LinkedinAltIcon from '@iconscout/react-unicons/icons/uil-linkedin-alt';
import DribbbleIcon from '@iconscout/react-unicons/icons/uil-dribbble';
import BehanceIcon from '@iconscout/react-unicons/icons/uil-behance';
import GithubAltIcon from '@iconscout/react-unicons/icons/uil-github-alt';
import PlusInCircleIcon from '@iconscout/react-unicons/icons/uil-plus-circle';
import MinusInCircleIcon from '@iconscout/react-unicons/icons/uil-minus-circle';
import AngleDownIcon from '@iconscout/react-unicons/icons/uil-angle-down';
import AngleRightIcon from '@iconscout/react-unicons/icons/uil-angle-right';
import CreditCardIcon from '@iconscout/react-unicons/icons/uil-credit-card';

import React from 'react';

export enum IconscoutIcons {
  MAP_MARKER = 'map-marker',
  CLOCK = 'clock',
  ARROW_UP_RIGHT = 'arrow-up-right',
  ARROW_DOWN = 'arrow-down',
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  LAYERS = 'layers',
  SKYPE = 'skype',
  TELEGRAM = 'telegram',
  WHATSAPP_ALT = 'whatsapp-alt',
  LINKEDIN = 'linkedin',
  LINKEDIN_ALT = 'linkedin-alt',
  DRIBBBLE = 'dribbble',
  BEHANCE = 'behance',
  GITHUB_ALT = 'github-alt',
  PLUS_IN_CIRCLE = 'plus-in-circle',
  MINUS_IN_CIRCLE = 'minus-in-circle',
  ANGLE_DOWN = 'angle-down',
  ANGLE_RIGHT = 'angle-right',
  CREDIT_CARD = 'credit-card',
}

const IconscoutIconMap = (props: IIconscoutIconProps): Record<IconscoutIcons, JSX.Element> => ({
  [IconscoutIcons.MAP_MARKER]: <MapMarkerIcon className={props.className} color={props.color} />,
  [IconscoutIcons.CLOCK]: <ClockIcon className={props.className} color={props.color} />,
  [IconscoutIcons.ARROW_UP_RIGHT]: <ArrowUpRightIcon className={props.className} color={props.color} />,
  [IconscoutIcons.ARROW_DOWN]: <ArrowDownIcon className={props.className} color={props.color} />,
  [IconscoutIcons.ARROW_RIGHT]: <ArrowRightIcon className={props.className} color={props.color} />,
  [IconscoutIcons.ARROW_LEFT]: <ArrowLeftIcon className={props.className} color={props.color} />,
  [IconscoutIcons.LAYERS]: <LayersIcon className={props.className} color={props.color} />,
  [IconscoutIcons.SKYPE]: <SkypeIcon className={props.className} color={props.color} />,
  [IconscoutIcons.TELEGRAM]: <TelegramIcon className={props.className} color={props.color} />,
  [IconscoutIcons.WHATSAPP_ALT]: <WhatsappAltIcon className={props.className} color={props.color} />,
  [IconscoutIcons.LINKEDIN]: <LinkedinIcon className={props.className} color={props.color || '#2779B8'} />,
  [IconscoutIcons.LINKEDIN_ALT]: <LinkedinAltIcon className={props.className} color={props.color} />,
  [IconscoutIcons.DRIBBBLE]: <DribbbleIcon className={props.className} color={props.color} />,
  [IconscoutIcons.BEHANCE]: <BehanceIcon className={props.className} color={props.color} />,
  [IconscoutIcons.GITHUB_ALT]: <GithubAltIcon className={props.className} color={props.color} />,
  [IconscoutIcons.PLUS_IN_CIRCLE]: <PlusInCircleIcon className={props.className} color={props.color} />,
  [IconscoutIcons.MINUS_IN_CIRCLE]: <MinusInCircleIcon className={props.className} color={props.color} />,
  [IconscoutIcons.ANGLE_DOWN]: <AngleDownIcon className={props.className} color={props.color} />,
  [IconscoutIcons.ANGLE_RIGHT]: <AngleRightIcon className={props.className} color={props.color} />,
  [IconscoutIcons.CREDIT_CARD]: <CreditCardIcon className={props.className} color={props.color} />,
});

export interface IIconscoutIconProps {
  icon: IconscoutIcons;
  className?: string;
  color?: string;
}

export default function IconscoutIcon(props: IIconscoutIconProps) {
  return IconscoutIconMap(props)[props.icon];
}
