import React from 'react';
import ExplanationSection, {
  ExplanationSectionsArray,
} from '../../containers/sections/explanation-section/explanation-section';
import { DuotoneIcons } from '../../components/Atoms/icon/duotone-icon';

interface IExplanationSectionStoryblokComponentBlok {
  sectionTitle: string;
  icon: DuotoneIcons;
  items: { title: string; description: string }[];
}
export function ExplanationSectionStoryblokComponent({ blok }: { blok: IExplanationSectionStoryblokComponentBlok }) {
  return <ExplanationSection sectionTitle={blok.sectionTitle} sectionIconName={blok.icon} paragraphs={blok.items} />;
}

export function ExplanationSectionArrayStoryblokComponent({
  blok,
}: {
  blok: { explanationSections: IExplanationSectionStoryblokComponentBlok[] };
}) {
  return (
    <ExplanationSectionsArray>
      {blok?.explanationSections?.map((blok: any) => (
        <ExplanationSectionStoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </ExplanationSectionsArray>
  );
}
