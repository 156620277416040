import React from 'react';
import TechnologiesSection from '../../containers/sections/technologies-section/technologies-section';
import { IStoryblokImageAsset } from '../types';

interface ITechnologiesSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  technologies: {
    icon: IStoryblokImageAsset;
    title: string;
    type: string[];
  }[];
}
export function TechnologiesSectionStoryblokComponent({ blok }: { blok: ITechnologiesSectionStoryblokComponentBlok }) {
  // Create filters array by all possible technologies.type values
  const filters: string[] = Array.from(new Set(blok.technologies.flatMap((tech) => tech.type).map((type) => type)));

  return (
    <TechnologiesSection
      title={blok.sectionTitle}
      description={blok.sectionDescription}
      filters={filters.map((filter) => ({ text: filter }))}
      technologies={blok.technologies.map((t) => ({ ...t, icon: t.icon.filename }))}
    />
  );
}
