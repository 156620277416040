import React from 'react';
import Section from '../section/section';
import BenefitsSectionAchievements, { IBenefitsSectionAchievement } from './benefits-section-achievements';
import BenefitsSectitonBenefits, { IBenefitItem } from './benefits-section-benefits';
import BenefitsSectionRatings, { IBenefitsRatingItem } from './benefits-section-ratings';

export interface IBenefitsSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  benefits: IBenefitItem[];
  achievements: IBenefitsSectionAchievement[];
  ratings: IBenefitsRatingItem[];
}
export default function BenefitsSection({
  sectionDescription,
  sectionTitle,
  achievements,
  benefits,
  ratings,
}: IBenefitsSectionProps) {
  return (
    <div className="benefits-section">
      <Section title={sectionTitle} description={sectionDescription}>
        <BenefitsSectitonBenefits benefits={benefits} />
        <BenefitsSectionAchievements achievements={achievements} />
        <BenefitsSectionRatings items={ratings} />
      </Section>
    </div>
  );
}
