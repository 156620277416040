import classNames from 'classnames';
import React from 'react';
import { ScreenSizesEnum, useBreakpoint } from '../../../hooks/use-breakpoint';
import TechnologiesIcon, { TechnologiesIcons } from '../../../components/Atoms/icon/technologies-icon';

export interface ITechnologiesListItem {
  type: string[];
  title: string;
  icon: TechnologiesIcons | string;
}

interface ITechnologiesSectionListProps {
  technologies: ITechnologiesListItem[];
  activeFilter: string;
}
export default function TechnologiesSectionList({ technologies, activeFilter }: ITechnologiesSectionListProps) {
  const isMobile = useBreakpoint(ScreenSizesEnum.Mobile);

  const displayAll = () => true;
  const displayByFilter = (type: string[]) => type.includes(activeFilter);

  return (
    <div className="technologies-section__technologies">
      {technologies
        .filter((tech) => (isMobile ? displayByFilter(tech.type) : displayAll()))
        .map((tech, idx) => (
          <div
            key={idx}
            className={classNames(
              'technologies-section__technologies__item',
              tech.type.includes(activeFilter) && 'technologies-section__technologies__item--active'
            )}>
            <TechnologiesIcon icon={tech.icon} alt={tech.title} />
            <span>{tech.title}</span>
          </div>
        ))}
    </div>
  );
}
