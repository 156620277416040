export enum StoryblokComponentsEnum {
  Header = 'header',
  Footer = 'footer',
  FaqSection = 'faq-section',
  PrimarySection = 'primary-section',
  PrimarySection_CaseView = 'primary-section-case-view',
  PrimarySectionFooterAction = 'primary-section-footer-action',
  PrimarySectionFooterCase = 'primary-section-footer-case',
  SliderSection = 'slider-section',
  BenefitsSection = 'benefits-section',
  SpecializationsSection = 'specializations-section',
  ServicesSection = 'services-section',
  TechnologiesSection = 'technologies-section',
  TestimonialsSection = 'testimonials-section',
  ConnectSection = 'connect-section',
  OrderedListSection = 'ordered-list-section',
  ServicesBenefitsSection = 'services-benefits-section',
  WhyCompaniesChooseUsSection = 'why-companies-choose-us-section',
  SetupACallSection = 'setup-a-call-section',
  SolutionSection = 'solution-section',
  RelatedCaseStudiesSection = 'related-case-studies-section',
  GradientBackgroundBlock = 'gradient-background-block',
  SimpleSection = 'simple-section',
  ExplanationSection = 'explanation-section',
  ExplanationSectionArray = 'explanation-section-array',
  IoTMobileDevelopmentSection = 'iot-mobile-development-section',
}
