import React from 'react';
import { DuotoneIconSizes, DuotoneIcons } from '../../../components/Atoms/icon/duotone-icon';
import Text, { TextTypographies, TextWeights } from '../../../components/Atoms/text/text';
import Title, { TitleTypes } from '../../../components/Atoms/text/title';
import Icon from '../../../components/Atoms/icon/icon';
import { Link } from 'gatsby';

export interface IHeaderMenuItemProps {
  icon: DuotoneIcons;
  title: string;
  description?: string;
  link?: string;
  onClick?: () => void;
  closeMenu?: () => void;
}
export default function HeaderMenuItem({ icon, title, description, link, onClick, closeMenu }: IHeaderMenuItemProps) {
  const onClickHandler = () => {
    closeMenu?.();
    onClick?.();
  };

  return (
    <Link to={link || ''} onClick={onClickHandler}>
      <div className="header-menu__column__list__item__left">
        <Icon icon={icon} iconSize={DuotoneIconSizes.MEDIUM} />
      </div>

      <div className="header-menu__column__list__item__right">
        <Title
          className="header-menu__column__list__item__title"
          titleType={TitleTypes.HEADER_6}
          text={{ text: title, weight: TextWeights.BOLD }}
        />

        {description && (
          <Text
            className="header-menu__column__list__item__description"
            typography={TextTypographies.BODY_SMALL}
            text={description}
          />
        )}
      </div>
    </Link>
  );
}
