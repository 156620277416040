import React from 'react';
import { Tag } from '../../../../components/tag/tag-component';
import Text, { ITextProps } from '../../../../components/Atoms/text/text';
import { IconscoutIcons } from '../../../../components/Atoms/icon/iconscout-icon';
import TechnologiesIcon from '../../../../components/Atoms/icon/technologies-icon';
import { Link } from 'gatsby';
import { Button } from '../../../../components/button/button';

export interface ISliderSectionInfoBlockProps {
  tag?: ITextProps;
  name: string;
  title: string;
  description: string;
  technologies: {
    src: string;
    alt: string;
  }[];
  location: ITextProps;
  footerText: string;
  link: string;
}
export default function SliderSectionInfoBlock({
  tag,
  name,
  title,
  description,
  technologies,
  location,
  footerText,
  link,
}: ISliderSectionInfoBlockProps) {
  return (
    <Link to={`/${link}`} className="slider-section__info-block">
      <div className="slider-section__info-block__header">
        {tag && (
          <div className="slider-section__info-block__header__tag">
            <Text {...tag} />
          </div>
        )}
        <Button isExtra textContent={name} />
      </div>

      <h3>{title}</h3>

      <p>{description}</p>

      <div className="slider-section__info-block__tags">
        <Tag text={location} iconName={IconscoutIcons.MAP_MARKER} />
        {technologies.map((tech, idx) => (
          <TechnologiesIcon icon={tech.src} alt={tech.alt} key={idx} />
        ))}
      </div>

      <div className="slider-section__info-block__footer">
        <p>{footerText}</p>
      </div>
    </Link>
  );
}
