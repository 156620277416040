import React from 'react';
import ServicesSection, { ServicesSectionTypesEnum } from '../../containers/sections/services-section/services-section';
import { IServiceTileStorybookComponentBlok } from './service-tile';

interface IServicesSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  services: IServiceTileStorybookComponentBlok[];
  type: ServicesSectionTypesEnum;
  grayBackground: boolean;
}
export function ServicesSectionStoryblokComponent({ blok }: { blok: IServicesSectionStoryblokComponentBlok }) {
  return (
    <ServicesSection
      grayBackground={blok.grayBackground}
      title={blok.sectionTitle}
      description={blok.sectionDescription}
      services={blok.services.map((i) => ({
        link: i.link.url || i.link.cached_url,
        icon: i.icon,
        text: i.text,
        title: i.title,
      }))}
      type={blok.type}
    />
  );
}
