import React from 'react';
import Avatar from '../../avatar/avatar';
import { IconscoutIcons } from '../../Atoms/icon/iconscout-icon';
import Card, { CardAlignments, CardTypes } from '../../Atoms/card/card';
import LinkTitle, { LinkTitleArrowPositionEnum } from '../../Atoms/text/link-title';
import Title, { TitleTypes } from '../../Atoms/text/title';
import Text, { TextTypographies, TextWeights } from '../../Atoms/text/text';
import Icon from '../../Atoms/icon/icon';

export interface ITestimonialFullProps {
  avatarUrl: string;
  author: { name: string; position: string };
  location: string;
  budget: string;
  duration: string;
  content: {
    logo: string;
    title: string;
    text: string;
  };
  link?: string;
}
export default function TestimonialFull(props: ITestimonialFullProps) {
  const additionals = [
    {
      title: 'Location',
      icon: IconscoutIcons.MAP_MARKER,
      value: props.location,
    },
    {
      title: 'Budget',
      icon: IconscoutIcons.CREDIT_CARD,
      value: props.budget,
    },
    {
      title: 'Duration',
      icon: IconscoutIcons.CLOCK,
      value: props.duration,
    },
  ];

  return (
    <Card
      link={props.link}
      className="testimonial testimonial-full"
      cardAlignment={CardAlignments.LEFT}
      cardType={CardTypes.COLUMN}>
      <div className="testimonial-full__author">
        <Avatar imgSrc={props.avatarUrl} />
        <LinkTitle
          className="testimonial-full__author__caption"
          titleText={{
            titleType: TitleTypes.HEADER_4,
            text: { text: props.author.name },
            className: 'testimonial-full__author__name',
          }}
          subtitle={{
            className: 'testimonial-full__author__position',
            typography: TextTypographies.BODY_SMALL,
            text: props.author.position,
          }}
          arrowPosition={LinkTitleArrowPositionEnum.Up}
        />
      </div>

      <div className="testimonial-full__additionals">
        {additionals.map((item, idx) => (
          <div key={idx} className="testimonial-full__additionals__item">
            <span className="testimonial-full__additionals-group">
              <Icon icon={item.icon} />
              <Text
                text={item.title}
                typography={TextTypographies.CONTROL_LABEL}
                className="testimonial-full__additionals__item__title"
              />
            </span>

            <Text
              text={item.value}
              typography={TextTypographies.BODY_SMALL}
              className="testimonial-full__additionals__item__value"
            />
          </div>
        ))}
      </div>

      <div className="testimonial-full__content">
        <div className="testimonial-full__content__logo">
          <img src={props.content.logo} alt="testimonial-logo" />
        </div>
        <div className="testimonial-full__content__text">
          <Title
            titleType={TitleTypes.HEADER_6}
            text={{
              text: props.content.title,
              typography: TextTypographies.BODY_SMALL,
              weight: TextWeights.BOLD,
            }}
          />
          <Text text={props.content.text} typography={TextTypographies.BODY_SMALL} weight={TextWeights.REGULAR} />
        </div>
      </div>
    </Card>
  );
}
