import classNames from 'classnames';
import React from 'react';

export enum AvatarSizesEnum {
  Small = 'small',
  Big = 'big',
}

interface IAvatarProps {
  imgSrc: string;
  size?: AvatarSizesEnum;
}
export default function Avatar({
  imgSrc,
  size = AvatarSizesEnum.Small,
}: IAvatarProps) {
  return (
    <div className={classNames('avatar', `avatar-size-${size}`)}>
      <img src={imgSrc} alt="avatar" />
    </div>
  );
}
