import classNames from 'classnames';
import React from 'react';
import { Contact, IContactProps } from '../../../../components/contact/contact';

interface IContactCardProps {
  className?: string;
  contacts: IContactProps[];
}

export const ContactCard = (props: IContactCardProps): JSX.Element => (
  <div
    className={classNames('connect-section__contacts-card', props.className)}>
    <h3 className="connect-section__contacts-headline">
      We are open to answer you directly
    </h3>
    <div className="connect-section__contacts">
      {props.contacts.map((element: IContactProps, key: number) => (
        <Contact key={key} {...element} />
      ))}
    </div>
  </div>
);
