import React from 'react';
import { SetUpACallSection } from '../../containers/sections/set-up-a-call-section/set-up-a-call-section';
import { IConnectTileStorybookComponentBlok } from './connect-tile';

interface ISetupACallSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  connectTile: IConnectTileStorybookComponentBlok[];
}

export function SetupACallSectionStoryblokComponent({ blok }: { blok: ISetupACallSectionStoryblokComponentBlok }) {
  return (
    <SetUpACallSection
      sectionsTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      tileConnectProps={{
        ...blok.connectTile[0],
        link: blok.connectTile[0].link?.cached_url || blok.connectTile[0].link?.url,
      }}
    />
  );
}
