import React from 'react';
import { ISliderSectionSlider } from '../slider-section';
import { Link } from 'gatsby';
import { Button } from '../../../../components/button/button';

interface ISliderSectionMobileLayoutProps {
  slides: ISliderSectionSlider[];
}
export default function SliderSectionMobileLayout({ slides }: ISliderSectionMobileLayoutProps) {
  return (
    <div className="slider-section__simple-wrapper">
      {slides.map((slide, idx) => (
        <Link key={idx} to={slide.info.link} className="slider-section__simple-info-block">
          <div className="slider-section__simple-info-block__info">
            <Button isExtra textContent={slide.info.name} />
            <h3>{slide.info.title}</h3>
          </div>
          <div className="slider-section__simple-info-block__image">
            <img src={slide.image.src} alt={slide.image.alt} />
          </div>
        </Link>
      ))}
    </div>
  );
}
