import React from 'react';
import { Button } from '../button/button';
import Card, { CardTypes } from '../Atoms/card/card';
import Title, { TitleTypes } from '../Atoms/text/title';
import Text, { TextTypographies, TextWeights } from '../Atoms/text/text';
import DuotoneIcon, { DuotoneIconSizes, DuotoneIcons } from '../Atoms/icon/duotone-icon';

export interface IConnectTileProps {
  headlineContent: string;
  description: string;
  icon: DuotoneIcons;
  buttonText?: string;
  link?: string;
  onClick?: () => void;
}

export const ConnectTile = (props: IConnectTileProps): JSX.Element => (
  <Card link={props.link} cardType={CardTypes.ADAPTIVE_ROW} className="connect-tile">
    <DuotoneIcon className="connect-tile__icon" icon={props.icon} iconSize={DuotoneIconSizes.BIG} />
    <div className="connect-tile__section">
      <Title
        className="connect-tile__headline"
        text={{
          text: props.headlineContent,
          weight: TextWeights.BOLD,
        }}
        titleType={TitleTypes.HEADER_3}
      />
      <Text
        className="connect-tile__description"
        text={props.description}
        typography={TextTypographies.CONTROL_LABEL}
        weight={TextWeights.REGULAR}
      />
      {props.buttonText && <Button textContent={props.buttonText} onClick={props.onClick} isExtra />}
    </div>
  </Card>
);
