import React from 'react';
import DuotoneIcon, { DuotoneIcons, DuotoneIconSizes } from './duotone-icon';
import IconscoutIcon, { IconscoutIcons } from './iconscout-icon';

export type IconType = DuotoneIcons | IconscoutIcons;

export interface IIconProps extends React.HTMLAttributes<HTMLElement> {
  icon: IconType;
  iconSize?: DuotoneIconSizes;
  color?: string;
}

export default function Icon(props: IIconProps) {
  if (Object.values(DuotoneIcons).includes(props.icon as DuotoneIcons)) {
    return <DuotoneIcon {...props} icon={props.icon as DuotoneIcons} iconSize={props.iconSize} />;
  } else {
    return <IconscoutIcon {...props} icon={props.icon as IconscoutIcons} color={props.color} />;
  }
}
