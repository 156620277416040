import React from 'react';
import { DuotoneIcons } from '../Atoms/icon/duotone-icon';
import Title, { TitleTypes } from '../Atoms/text/title';
import Text, { TextTypographies, TextWeights } from '../Atoms/text/text';
import LinkTitle from '../Atoms/text/link-title';
import Card from '../Atoms/card/card';

export interface IServiceTileProps {
  icon: DuotoneIcons;
  link?: string;
  title: string;
  text: string;
}

export default function ServiceTile({ icon, link, title, text }: IServiceTileProps) {
  return (
    <Card link={link} className="service-tile">
      {link ? (
        <LinkTitle className="service-tile__header" icon={icon} />
      ) : (
        <Title titleType={TitleTypes.HEADER_6} icon={icon} />
      )}
      <div className="service-tile__content">
        <Title
          className="service-tile__title"
          titleType={TitleTypes.HEADER_3}
          text={{ text: title, weight: TextWeights.BOLD }}
        />
        <Text className="service-tile__description" text={text} typography={TextTypographies.BODY_SMALL} />
      </div>
    </Card>
  );
}
