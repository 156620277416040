import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../../components/Atoms/icon/icon';
import { IconscoutIcons } from '../../../components/Atoms/icon/iconscout-icon';

interface IServicesBenefitsSectionHeaderProps {
  description: string;
}
export default function ServicesBenefitsSectionHeader({
  description,
}: IServicesBenefitsSectionHeaderProps) {
  const DESCRIPTION_BLOCK_MIN_HEIGHT = '160px';
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const descriptionWrapperRef = useRef<HTMLDivElement>(null);
  const [textLimited, setTextLimited] = useState(true);
  const [showExpandButton, setShowExpandButton] = useState(false);

  useEffect(() => {
    if (!descriptionRef.current || !descriptionWrapperRef.current) {
      return;
    }

    setShowExpandButton(
      descriptionRef.current.clientHeight !==
        descriptionWrapperRef.current.clientHeight
    );
  }, [descriptionRef, descriptionWrapperRef]);

  const showMoreButtonHandler = () => {
    setTextLimited((prev) => !prev);
  };

  return (
    <div className="section-header">
      <div
        className="services-benefits-section__description"
        ref={descriptionWrapperRef}
        style={{
          maxHeight: textLimited
            ? DESCRIPTION_BLOCK_MIN_HEIGHT
            : descriptionRef?.current?.clientHeight,
        }}>
        <p ref={descriptionRef}>{description}</p>
      </div>
      {showExpandButton && (
        <div
          className={classNames(
            'services-benefits-section__show-more-label',
            textLimited && 'services-benefits-section__show-more-label--active'
          )}
          onClick={showMoreButtonHandler}>
          <span className="services-benefits-section__show-more-label__title">
            {textLimited ? 'Show more' : 'Show less'}
          </span>
          <span className="services-benefits-section__show-more-label__icon-wrapper">
            {textLimited ? (
              <Icon icon={IconscoutIcons.PLUS_IN_CIRCLE} />
            ) : (
              <Icon icon={IconscoutIcons.MINUS_IN_CIRCLE} />
            )}
          </span>
        </div>
      )}
    </div>
  );
}
