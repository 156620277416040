import React from 'react';
import { isInternalLink } from '../../../helpers/is-link-external';
import { Link } from 'gatsby';

export enum CardTypes {
  COLUMN = 'column',
  ADAPTIVE_ROW = 'adaptive-row',
  ROW = 'row',
}

export enum CardAlignments {
  LEFT = 'align-left',
  CENTER = 'align-center',
  RIGHT = 'align-right',
}

export interface ICardProps extends React.HTMLAttributes<HTMLElement> {
  cardType?: CardTypes;
  cardAlignment?: CardAlignments;
  className?: string;
  link?: string;
  hasTransparentBackground?: boolean;
}

const defineClasses = ({ cardAlignment, cardType, hasTransparentBackground, className }: ICardProps): string => {
  let result = 'card';

  if (cardAlignment) {
    result += ' card--' + cardAlignment;
  } else {
    result += ' card--' + CardAlignments.LEFT;
  }

  if (cardType) {
    result += ' card--' + cardType;
  } else {
    result += ' card--' + CardTypes.COLUMN;
  }

  if (hasTransparentBackground) {
    result += ' card--transparent';
  }

  if (className) {
    result += ` ${className}`;
  }

  return result;
};

export default function Card(props: ICardProps) {
  if (!props.link) {
    return (
      <div style={props.style} className={defineClasses(props)}>
        {props.children}
      </div>
    );
  }

  if (isInternalLink(props.link)) {
    return (
      <Link style={props.style} className={defineClasses(props)} to={`/${props.link}`}>
        {props.children}
      </Link>
    );
  }

  return (
    <a style={props.style} className={defineClasses(props)} href={props.link} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}
