import React from 'react';
import BenefitsSection from '../../containers/sections/benefits-section/benefits-section';
import { DuotoneIcons } from '../../components/Atoms/icon/duotone-icon';

interface IBenefitsSectionStoryblokComponentBlok {
  sectionTitle: string;
  sectionDescription: string;
  achievements: { title: string; subtitle: string }[];
  benefits: {
    icon: DuotoneIcons;
    title: string;
    text: string;
  }[];
  ratings: {
    icon: { filename: string };
    subtitle: string;
    title: string;
  }[];
}
export function BenefitsSectionStoryblokComponent({ blok }: { blok: IBenefitsSectionStoryblokComponentBlok }) {
  return (
    <BenefitsSection
      sectionTitle={blok.sectionTitle}
      sectionDescription={blok.sectionDescription}
      achievements={blok.achievements.map((i) => ({ title: i.title, subtitle: i.subtitle }))}
      benefits={blok.benefits.map((i) => ({ icon: i.icon, title: i.title, text: i.text }))}
      ratings={blok.ratings.map((i) => ({ icon: i.icon.filename, subtitle: i.subtitle, title: i.title }))}
    />
  );
}
