import React from 'react';
import Section from '../section/section';
import Slider from '../../../components/slider/slider';
import TestimonialFull, {
  ITestimonialFullProps,
} from '../../../components/testimonial/testimonial-full/testimonial-full';

export interface ITestimonialsSectionProps {
  testimonials: ITestimonialFullProps[];
  sectionTitle: string;
}
export default function TestimonialsSection({ testimonials, sectionTitle }: ITestimonialsSectionProps) {
  return (
    <div className="testimonials-section">
      <Section title={sectionTitle}>
        <Slider
          slides={testimonials.map((item, idx) => (
            <TestimonialFull key={idx} {...item} />
          ))}
          options={{
            breakpoints: {
              '(min-width: 320px)': {
                slides: { perView: 1 },
              },
              '(min-width: 770px)': {
                slides: { perView: 2.1 },
              },
              '(min-width: 1154px)': {
                slides: { perView: 3 },
              },
            },
          }}
        />
      </Section>
    </div>
  );
}
