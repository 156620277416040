import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Text, { ITextProps, TextTypographies, TextWeights } from '../Atoms/text/text';

export interface IFilterProps {
  text: ITextProps;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: (...args: any[]) => unknown;
  className?: string;
  notSwitchOffByClick?: boolean;
}

export const Filter = ({
  text,
  isActive: initialActiveState = false,
  isDisabled,
  onClick,
  className,
  notSwitchOffByClick = false,
}: IFilterProps): JSX.Element => {
  const [isActive, setIsActive] = useState(initialActiveState);

  const handleClick = () => {
    onClick?.();

    if (isActive && notSwitchOffByClick) {
      return;
    }

    setIsActive((prev) => !prev);
  };

  useEffect(() => {
    setIsActive(initialActiveState);
  }, [initialActiveState]);

  return (
    <button
      disabled={isDisabled}
      onClick={() => handleClick()}
      className={classNames('filter', isActive && 'filter-active', className)}>
      <Text {...text} typography={TextTypographies.CONTROL_BUTTON} weight={TextWeights.BOLD} />
    </button>
  );
};
