import React, { useState } from 'react';
import { Paginator } from '../paginator/paginator';

import 'keen-slider/keen-slider.scss';
import { KeenSliderOptions } from 'keen-slider';
import { useKeenSlider } from 'keen-slider/react';

interface ISliderProps {
  slides: JSX.Element[];
  options?: KeenSliderOptions;
  noPaginator?: boolean;
  onChange?: (slideIdx: number) => void;
}
export default function Slider({ slides, options, noPaginator, onChange }: ISliderProps) {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const [thumbsCount, setThumbsCount] = useState(0);

  const [sliderRef, sliderInstanceRef] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 1,
      spacing: 0,
    },
    ...options,
    created: (slider) => {
      setThumbsCount(slider.track.details.maxIdx + 1);
    },
    updated: (slider) => {
      setThumbsCount(slider.track.details.maxIdx + 1);
    },
    slideChanged: (slide) => {
      setCurrentSlideIdx(slide.track.details.abs);
      onChange?.(slide.track.details.abs);
    },
  });

  const slideNextHandler = () => {
    sliderInstanceRef.current?.next();
  };

  const slidePrevHandler = () => {
    sliderInstanceRef.current?.prev();
  };

  const getCurrentSliderPerView = () => sliderInstanceRef.current?.options?.slides?.perView;

  return (
    <div className="slider">
      <div ref={sliderRef} className="keen-slider slider__wrapper">
        {slides.map((slide, idx) => (
          <div key={idx} className="keen-slider__slide slider__slide">
            {slide}
          </div>
        ))}
      </div>

      {!noPaginator && slides.length > getCurrentSliderPerView() && (
        <div className="slider__paginator">
          <Paginator
            currentItem={currentSlideIdx}
            itemsCount={thumbsCount}
            onNextClick={slideNextHandler}
            onPreviousClick={slidePrevHandler}
          />
        </div>
      )}
    </div>
  );
}
