import React from 'react';
import Section from '../section/section';
import { IAccordionItem, FaqSectionAccordion } from './accordion/faq-section-accordion';

export interface IFaqSectionProps {
  accordionItems: IAccordionItem[];
  sectionTitle: string;
}

export const FAQSection = (props: IFaqSectionProps): JSX.Element => (
  <section className="faq-section">
    <Section title={props.sectionTitle}>
      <FaqSectionAccordion items={props.accordionItems} />
    </Section>
  </section>
);
